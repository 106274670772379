import {Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import * as XLSX from 'xlsx';
import { MessagesService } from 'src/app/services/messages.service';
import { AppState } from 'src/app/services/app.service';
import { ActivatedRoute } from '@angular/router';
import { ImportComponent } from './import.component';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-import',
    templateUrl: './importChild.component.html',
    styleUrls: ['./import.component.scss'],
  })

export class ImportChildComponent extends ImportComponent implements OnInit {

    public idclass: string;

    constructor(getMsgError: MessagesService,
        adminService: AdminService,
        appState: AppState,
        translateService: TranslateService,
        activatedRoute: ActivatedRoute) {
        super(getMsgError, adminService, appState,translateService, activatedRoute);
    }

    protected getFileName() {
      return this.translateService.instant('messages.child_import');
    }

    protected buildTableParams(datas) : boolean 
    {
      let IsError: boolean = false;

      datas.forEach((x) => {

        x.errors = {
          username: null,
          password: null,
          birthdate : null
        }

        // check fields is not empty
        x.errors.password = this.onCheckEmptyField(x.password, 'login.password');
        x.errors.username = this.onCheckEmptyField(x.username, 'profile.username');
        x.errors.firstname = this.onCheckEmptyField(x.firstname, 'profile.firstname');
        x.errors.lastname = this.onCheckEmptyField(x.lastname, 'profile.lastname');
        x.errors.birthdate = this.onCheckEmptyField(x.birthdate, 'admin_import.birthdate');

        // check fields have errors
        if (!x.errors.password) {
          x.errors.password = this.getMsgError.getMsgPassword(x.password.toString());
        }
        if (!x.errors.username) {
          x.errors.username = this.getMsgError.getMsgUsername(x.username.toString());
        }
        if (!x.errors.birthdate) {
          x.errors.birthdate = this.getMsgError.getMsgDate(x.birthdate);
        }        
        
        if (x.errors.password || 
            x.errors.username || x.errors.birthdate ||
            x.errors.firstname || x.errors.lastname) {
            IsError = true;
            this.unActiveBtn = true;
            this.loading = true;
        }
      });

      return IsError;
    }

    public ngOnInit(): void { 
      
      this.school = this.appState.restore('school');
      this.idclass = this.activatedRoute.snapshot.params['idclass'] || null;
    }

    public getTitle() {

      return this.idclass ? 'admin_import.add_to_class' : 'admin_import.add_to_school';
    }

    public addUsers() {
        this.loading = false;
  
        this.users = this.datas.map((x) => {
          return {
            username: x.username,
            password: x.password,
            firstname: x.firstname,
            lastname: x.lastname,
            birthdate: moment(x.birthdate).unix()
          }
        })
        let school = this.appState.restore('school').idnumber || this.activatedRoute.snapshot.params['idschool'];
        let clas = this.activatedRoute.snapshot.params['idclass'] || null;
        
        this.adminService.addChildrenImport(school, this.users, clas).then((result) => {
          
          result.forEach((x) => {
            let user = this.datas.find((y) => x.username === y.username);
            if (x.token) {            
              user.status = 'saved';
            } else {
              user.status = 'error';
            }
          });

          this.adminService.resetChildrenByClassCache();
          this.adminService.resetChildrenCache();
  
          this.loading = true;
          this.unActiveBtn = true;
        })
    }
    protected listParameters() {

      return  [{
        username: null,
        password: null,
        firstname: null,
        lastname: null,
        birthdate: null
      }];
    }
    public getLocaleDate(date) {

      if (date) {
        // hotfix
        // library XLSX.utils.sheet_to_json lost 4seconds !!
        // then i add 1hour
        let temp = moment(moment(date).add(1, 'hours')).unix();

        let _date = new Date(temp * 1000);

        return  _date.toLocaleDateString();
      } else {
        return '';
      }


    }

}