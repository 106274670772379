import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../../shared/menu/menu.component';
import { AdminService, } from 'src/app/services/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState} from 'src/app/services';

@Component({
  selector: 'app-reflection24',
  templateUrl: './reflection24.component.html',
  styleUrls: ['./reflection24.component.scss']
})
export class Reflection24Component implements OnInit {

  public modes = [];
  public unknownPage: string = 'teacher_reflections.title';
  public school: any;

  constructor(private adminService: AdminService,  private router: Router,   private appState: AppState,  
      private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    
    this.modes = this.getModes();
    this.school = this.appState.restore('school');
  }

  public onModeChoose(mode:string): void {

    this.router.navigate([ mode ], { relativeTo: this.activatedRoute });
  }

  private getModes() {

    return [
        { key: 'analytics', titleKey: 'admin_tr.data_graphs' },
        { key: 'forms', titleKey: 'admin_tr.completed_forms' },
        { key: 'comments', titleKey: 'admin_tr.teacher_comments' }
    ]
  }

  public onChangeSchool(school) {
    this.school = school;
  }

}
