import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IResource, TeacherService, AppState } from '../../services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-daily-activity',
  templateUrl: './daily-activity.component.html',
  styleUrls: ['./daily-activity.component.scss'],
})
export class DailyActivityViewComponent implements OnInit, OnChanges {
  public areas: IArea[] = null;
  public objectives: string[] = null;
  public materials: string[] = null;
  public procedures: string[] = null;
  public support: string[] = null;
  public social: string[] = null;
  public templateResources: IResource[] = null;
  public proceduresTitle: string = this.translateService.instant('daily_activities.procedures_title_1');

  public loader: boolean = true;
  private xmlData: any = null;
  public language: string;
  public stateName: string;

  public bunner: any = { state: false, src: '', type: '' };

  @Input()
  public set xml(data: any) {
    this.xmlData = data;
  }

  @Input()
  public resources: IResource[] = null;

  @Input()
  public activity: string = null;

  @Input()
  public age: string = null;

  @Input()
  public standards: any = null;

  constructor(private teacherService: TeacherService,
              private router: Router,
              private appState: AppState,
              private activatedRoute: ActivatedRoute,
              private translateService: TranslateService) {}

  public ngOnInit(): void {
    this.language = this.translateService.currentLang;
    const school =  this.appState.restore('school');
    this.stateName = this.teacherService.getStateName(school.p_state)
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!!this.xmlData) {
      this.objectives = this.vmFind(this.xmlData, 'objectives');
      this.materials = this.vmFind(this.xmlData, 'materials');
      this.procedures = this.vmFind(this.xmlData, 'procedures');
      this.support = this.vmFind(this.xmlData, 'support');
      this.social = this.vmFind(this.xmlData, 'social');
      this.vmFindAreas(this.xmlData);
      this.vmFindRecources(this.xmlData);
    } else {
      this.vmResetAll();
    }

    if (this.age === 'in' || this.age === 't1') {
      this.proceduresTitle = this.translateService.instant('daily_activities.procedures_title_2');
    }

    if(this.standards) {
      this.loader = false;
    }
  }

  public openBunner(e: Event, url:string, type: string) : void {
    e.stopPropagation();
    e.preventDefault();
    this.bunner.state = true;
    this.bunner.src = url;
    this.bunner.type = type;
  }

  public onClickedOutside(e: Event) {
    if(this.bunner.state) {
      this.bunner.state = false;
    }
  }

  private vmFindAreas(data: any): void {
    const titles: Array<{ common: string; template: string }> = [{ common: 'topic', template: 'daily_activities.topic'}, 
                                                                 { common: 'name', template: 'daily_activities.activity_name' }];

    this.areas = data.elements
      .filter((_item: { type: string; name: string; elements: any[] }) => titles.some((_title) => _title.common === _item.name))
      .map((_item: { type: string; name: string; elements: any[] }) => ({ title: _item.name, message: !!_item.elements ? _item.elements[0].text : '' }))
      .map((_item) => {
        const title = titles.find((_title) => _title.common === _item.title);
        _item.title = title.template;
        return _item;
      });

      // if the tag 'topic' does not exist, make a template to show the cell
      let isTopic = this.areas.some((x) => x.title === 'daily_activities.topic');

      if (!isTopic) {
        this.areas.unshift({ title: 'daily_activities.topic', message: ''});
      }

      this.areas.sort((a,b) => (a.title < b.title) ? 1 : ((b.title < a.title) ? -1 : 0))

    // prepare area of development.
    //const areaTitle: string = this.teacherService.getActivities().find((_activity) => _activity.key === this.activity).title;
    //const area: { title: string; message: string } = { title: 'Area of Development', message: areaTitle };

    //this.areas.unshift(area);
  }

  private vmFind(data: any, name: string) {
    // Find all item from list
    return data.elements
      .find((_item: { type: string; name: string; elements: any[] }) => _item.name === name)
      .elements.filter((_element: { type: string; name: string; elements: any[] }) => _element.name === 'item')
      .map((_item: { type: string; name: string; elements: any[] }) => (!!_item.elements ? _item.elements[0].text : ''))
      .filter((_x: any) => !!_x);
  }

  private vmFindRecources(data: any): void {
    // Find all resources
    const resources = data.elements.find((_item: { type: string; name: string; elements: any[] }) => _item.name === 'resources');
    if (!!resources && resources.elements.length > 0) {
      this.templateResources = resources.elements
        .filter((_element: { type: string; name: string; elements: any[] }) => _element.name === 'resource')
        .map((_item: { type: string; name: string; attributes: any }) => {
          if (!!_item.attributes.src) {
            const resource = this.resources.find((_resource: IResource) => _resource.title === _item.attributes.src);
            return !!resource ? resource : null;
          } else {
            return null;
          }
        })
        .filter((_x: any) => !!_x);
    } else {
      this.templateResources = null;
    }
  }

  private vmResetAll(): void {
    this.areas = null;
    this.objectives = null;
    this.materials = null;
    this.procedures = null;
    this.support = null;
    this.social = null;
    this.templateResources = null;
  }
  public openStandards() {

    this.router.navigate(['home/ds', this.activity], { relativeTo: this.activatedRoute.parent });
}
}

interface IArea {
  title: string;
  message: string;
}
