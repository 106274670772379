import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'progress-chart',
  templateUrl: './progress-chart.component.html',
  styleUrls: ['./progress-chart.component.scss']
})

export class ProgressChartComponent implements OnInit, OnChanges {
  
  @Input() total: number = 100;   
  @Input() current: number = 0;   
  @Input() color: string; 

  public background_color: string = '';
  public progress: number = 0;    

  ngOnInit() {
    this.updateProgress();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.color || changes.current || changes.total) this.updateProgress();
  }

  updateProgress() {
    this.background_color = this.color;  
    this.progress = (this.current / this.total) * 100;         
  }
}