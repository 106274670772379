import { Injectable } from '@angular/core';
import { AppState } from 'src/app/services/app.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IClass } from 'src/app/interfaces/IClass';
import { ISchool } from 'src/app/interfaces/ISchool';
import { IAssessmentAge } from 'src/app/interfaces/IAssessmentAge';
import { ITeacher } from '../interfaces/ITeacher';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { IAssessmentDetails } from '../interfaces/IAssessmentDetails';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';


@Injectable({
    providedIn: 'root',
})
export class AdminService {
 
    private apiRoot: string = environment.api;
    private contentRoot: string = environment.cdn;
    private classesCache: Array<{key : string, value: IClass[]}> = [];
    private schoolsCache: ISchool[];
    private teachersByClassCache : Array<{key : string, value: ITeacher[]}> = [];
    private childrenByClassCache: Array<{key : string, value: ITeacher[]}> = [];
    private children: Array<{key : string, value: ITeacher[]}> = [];
    private teachers: Array<{key : string, value: ITeacher[]}> = [];
    private reflectionsCache: Array<{school : string, week: string, value: Reflection[]}> = [];
    private childrenByAssessmentCache: Array<{school : string, assessment: string, value: IAssessmentAge[]}> = [];
    private schoolsLogo: Array<{key: string, value: Blob}> = [];
    private logo: any;

    constructor(private http: HttpClient,
                private appState: AppState) {
        // empty        
    }
    public vmSetLogo(logo: any) {
        this.logo = logo;
    }
    public vmGetLogo() {
        return this.logo;
    }
    public vmResetLogo() {
        this.logo = null;
    }
    /** Get list of modes */
    public getModes() {

        return [
            { key: 'tr', title: 'Teacher Reflections', titleKey: 'admin_home.teacher_reflections' },
            { key: 'rep', title: 'Assessment', titleKey: 'admin_home.assessment' },
            { key: 'cl', title: 'Class Management', titleKey: 'admin_home.class_management' },
            { key: 'um', title: 'User Management', titleKey: 'admin_home.user_management' },
            { key: 'start', title: 'Resources', titleKey: 'admin_home.getting_started' },
            { key: 'shop', title: 'Shop for Materials', titleKey: 'admin_home.shop_for_materials'},
            { key: 'brand', title: 'Brand Management', titleKey: 'admin_home.brand_management'},
            { key: 'training', title: 'Training', titleKey: 'admin_home.training' }
        ]
    }
    /** Get list of school */
    public getSchool() {

        if (!!this.schoolsCache) {
            return Promise.resolve(this.schoolsCache);
        } else {
            // create session header
            const session = this.appState.restore('session');
            const headers = new HttpHeaders({ 'x-vms-session': session });

            // call API to get list of schools
            const schoolsUrl = `${this.apiRoot}/admin/schools`;
            return this.http.get(schoolsUrl, {headers})
                            .toPromise()
                            .then((schools: ISchool[]) =>{
                                this.schoolsCache = schools;
                                return schools;
                            })
        }
        
    }

    public editSchool(school: any, color: string, logo: number) {
        
        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });
        // call API to get list of classes
        const url = `${this.apiRoot}/admin/school`;
        const body = {
            'idnumber': school.idnumber,
            'name': school.name,
            'color': color,
            'logo': logo,
            'is_active': 1,
         };
         

        return this.http.put(url, body, { headers })
                        .toPromise();       

    }

    public getTeachers(school){

        let element = this.teachers.find(x=>x.key == school);

        if (element) {
            return Promise.resolve(element.value);
        } else {
            // create session header
            const session = this.appState.restore('session');
            const headers = new HttpHeaders({ 'x-vms-session': session });
            // call API to get list of teachers
            const schoolsUrl = `${this.apiRoot}/admin/teacher`;
            let params = new HttpParams();
            params = params.append('school', school);
            return this.http.get(schoolsUrl, {params, headers})
                            .toPromise()
                            .then((teachers: ITeacher[]) =>{

                                this.teachers.push({
                                    key:   school,
                                    value: teachers
                                });

                                return teachers;
                            })
        }
    }
    private getSignedUrlLogo(school: string) {
        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });
    
        // call API to generate signed URL's
        const signUrl = `${this.apiRoot}/admin/school/${school}/signed-school-logo`;
        return this.http
          .get<string>(signUrl, { headers })
          .toPromise();
    }
    public getResourceLogo(school: string) {
          
        let element = this.schoolsLogo.find(x=>x.key == school);

        if (element) {
            return Promise.resolve(element.value);
        } else {
            let root = 'School_Resources';

            let url =  `${this.contentRoot}/${root}/${school}/${school}_logo.png`;
            
            return this.http.get(url, { responseType: 'blob', withCredentials: true })
                            .toPromise()
                            .then((res) =>{
    
                this.schoolsLogo.push({
                    key: school,
                    value: res
                });
                return res;
            })
        }
    }

    private readAsDataURL(file) {
		return new Promise((resolve, reject)=>{
			let fileReader = new FileReader();
			fileReader.onload = function(){
				return resolve({data:fileReader.result, name:file.name, size: file.size, type: file.type});
			}
			fileReader.readAsDataURL(file);
		})
    }

    public async getResourceBlobImage(url: string) {
        
        const img = new Image();
        img.src = url;
        await img.decode();
        const blob = await fetch(img.src).then((res) => res.blob());
        let files = [blob];
        let images : any = await Promise.all(files.map(f=>{return this.readAsDataURL(f)}));
        this.logo = images[0].data;
        return this.logo;     
    }

    public async updateLogo(file: any, school) {

        if (!file) { return Promise.resolve(); }
        // generate signed URL's
        const signedURL = await this.getSignedUrlLogo(school);

        const headers = new HttpHeaders({ 'Content-Type': 'image/png' });
        const blob = await fetch(file).then((res) => res.blob());

        return this.http.put(signedURL, blob, { headers: headers }).toPromise();
    }

    public getChildren(school){

        let element = this.children.find(x=>x.key == school);

        if (element) {
            return Promise.resolve(element.value);
        } else {
            // create session header
            const session = this.appState.restore('session');
            const headers = new HttpHeaders({ 'x-vms-session': session });
            // call API to get list of teachers
            const schoolsUrl = `${this.apiRoot}/admin/child`;
            let params = new HttpParams();
            params = params.append('school', school);
            return this.http.get(schoolsUrl, {params, headers})
                            .toPromise()
                            .then((children: ITeacher[]) =>{

                                this.children.push({
                                    key:   school,
                                    value: children
                                });

                                return children;
                            })
        }
    }

    public resetClassesCache() {
        this.classesCache = [];
    }
    public resetTeachersCache() {
        this.teachers = [];
    }
    public resetChildrenCache() {
        this.children = [];
    }
    public resetChildrenByClassCache() {
        this.childrenByClassCache = [];
    }
    public resetTeacherByClassCache() {
        this.teachersByClassCache = [];
    }
    public resetReflectionsCache() {
        this.reflectionsCache = [];
    }
    public resetSchoolsCache() {
        this.schoolsCache = null;
    }
    public resetSchoolLogoCach() {
        this.schoolsLogo = [];
    }
    public resetChildrenByAssessmentCache() {
        this.childrenByAssessmentCache = []
    }

    public resetAllCache(){

        this.resetClassesCache(),
        this.resetTeachersCache(),
        this.resetChildrenCache(),
        this.resetChildrenByClassCache(),
        this.resetTeacherByClassCache(),
        this.resetReflectionsCache(),
        this.resetSchoolsCache(),
        this.vmResetLogo()
        this.resetSchoolLogoCach(),
        this.resetChildrenByAssessmentCache()
    }

    public getClasses(school) {

        let element = this.classesCache.find(x=>x.key == school);

        if (element) {
            return Promise.resolve(element.value);
        } else {
            // create session header
            const session = this.appState.restore('session');
            const headers = new HttpHeaders({ 'x-vms-session': session });

            // call API to get list of classes
            const schoolsUrl = `${this.apiRoot}/admin/class`;
            let params = new HttpParams();
            params = params.append('school', school);
            return this.http.get(schoolsUrl, {params, headers})
                            .toPromise()
                            .then((classes: IClass[]) =>{

                                this.classesCache.push({
                                    key:   school,
                                    value: classes
                                });
                                return classes;
                            })
        }

    }

    public getCleverClassListBySchool(idnumber: string, district?: string): Promise<IClass[]> {
        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });

        let url =  `${this.apiRoot}/clever/school/${idnumber}/classes`;

        let params = new HttpParams();
        params = params.append('district', district);
    
        // Виконання запиту до бекенду
        return this.http.get<IClass[]>(url, { params, headers })
        .toPromise()
        .then((classes: IClass[]) => {
                
                return classes;
            });
    }

    public syncClass(district_id: string, school_id: string, class_id: string): Promise<any> {
       
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });

        const body = {
            'district_id': district_id,
            'school_id': school_id,
            'class_id': class_id
        };

        let url =  `${this.apiRoot}/clever/school/sync-class`;
        return this.http.post(url, body, { headers })
                        .toPromise()
                        .then(response => {
                            return response;
                        })
                        .catch(error => {

                            console.error('Error syncing class:', error);
                            throw error;
                        });
    }

    public getTeachersByClass(idclass : string) {
        let element = this.teachersByClassCache.find(x=>x.key == idclass);
        if (element) {
             return Promise.resolve(element.value);
        } else {
            // create session header
            const session = this.appState.restore('session');
            const headers = new HttpHeaders({ 'x-vms-session': session });

            const url = `${this.apiRoot}/admin/teacher`;
            let params = new HttpParams();
            params = params.append('class', idclass);
            return this.http.get(url, {params, headers})
                            .toPromise()
                            .then((teachers: ITeacher[]) =>{
                                this.teachersByClassCache.push({
                                    key:   idclass,
                                    value: teachers
                                });
                                return teachers;
                            })
        }
    }
    public getChildrenByClass(idclass) {

        let element = this.childrenByClassCache.find(x=>x.key == idclass);

        if (element) {
            return Promise.resolve(element.value);
        } else {
            // create session header
            const session = this.appState.restore('session');
            const headers = new HttpHeaders({ 'x-vms-session': session });

            const url = `${this.apiRoot}/admin/child`;
            let params = new HttpParams();
            params = params.append('class', idclass);
            return this.http.get(url, {params, headers})
                            .toPromise()
                            .then((children: ITeacher[]) =>{
                             
                                this.childrenByClassCache.push({
                                    key:   idclass,
                                    value: children
                                });
                                return children;
                            })
        }
    

    }

    public getChildrenByAssessment(school, assessment) {


        let element = this.childrenByAssessmentCache.find(x=>x.school == school && x.assessment === assessment);

        if (element) {
            return Promise.resolve(element.value);
        } else {

            // create session header
            const session = this.appState.restore('session');
            const headers = new HttpHeaders({ 'x-vms-session': session });

            const url = `${this.apiRoot}/admin/assessment/students`;
            let params = new HttpParams();

            params = params.append('school', school);
            params = params.append('assessment_age', assessment);

            return this.http.get(url, {params, headers})
                            .toPromise()
                            .then((assessmentage: IAssessmentAge[]) =>{

                                this.childrenByAssessmentCache.push({
                                    school:   school,
                                    assessment: assessment,
                                    value: assessmentage
                                });
                                return assessmentage;
                            });    
        }

    }

    public editClasses(clas, idschool, teacher) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });
        // call API to get list of classes
        const url = `${this.apiRoot}/admin/class`;
        const body = {
            'idnumber': clas.idnumber,
            'name': clas.name,
            'school': idschool,
            'date_begin': clas.date_begin,
            'date_end': clas.date_end,
            'teacher': teacher,
            'age_group': clas.age_group
         };
         

        return this.http.put(url, body, { headers })
                        .toPromise();       

    }
    public addClasses(name, idschool, date_begin, date_end, teacher, age_group) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });

        const url = `${this.apiRoot}/admin/class`;
        const body = {
            'name': name,
            'school': idschool,
            'date_begin': date_begin,
            'date_end': date_end,
            'teacher': teacher,
            'age_group': age_group
         };
         

        return this.http.post(url, body, { headers })
                        .toPromise();       

    }
    public addTeacher(user, school) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });

        const url = `${this.apiRoot}/admin/teacher`;
        const body = {
            "school": school,
            "username": user.username,
            "password": user.password,
            "firstname": user.firstname,
            "lastname": user.lastname,
            "email": this.setTeacherEmail()
         };
         

        return this.http.post(url, body, { headers })
                        .toPromise();     
    }

    private setTeacherEmail() {
        // users dont enter email for teacher
        // email for teachers is constanta
        const email = 'teacher@learningbeyondpaper.com';

        return email;
    }
    private setChildEmail() {
        // users dont enter email for teacher
        // email for teachers is constanta
        const email = 'child@learningbeyondpaper.com';

        return email;
    }

    public editTeacher(user, school) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });

        const url = `${this.apiRoot}/admin/teacher`;
        const body = {
            "school": school,
            "username": user.username,
            "password": user.password,
            "firstname": user.firstname,
            "lastname": user.lastname,
            "email": user.email
         };
         

        return this.http.put(url, body, { headers })
                        .toPromise();     
    }
    public addChild(user, school, clas?: string) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });

        const url = `${this.apiRoot}/admin/child`;
        const body = {
            "school": school,
            "class": clas,
            "username": user.username,
            "password": user.password,
            "firstname": user.firstname,
            "lastname": user.lastname,
            "email": this.setChildEmail(),
            "birthdate": user.birthdate
         };
         

        return this.http.post(url, body, { headers })
                        .toPromise();     
    }
    public editChild(user, school) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });

        const url = `${this.apiRoot}/admin/child`;
        const body = {
            "school": school,
            "username": user.username,
            "password": user.password,
            "firstname": user.firstname,
            "lastname": user.lastname,
            "email": user.email,
            "birthdate": user.birthdate
         };
         

        return this.http.put(url, body, { headers })
                        .toPromise();     
    }
    public deleteClass(idnumber: string){

        const session = this.appState.restore('session');
        const url = `${this.apiRoot}/admin/class`;

        const options = {
            headers: new HttpHeaders({
                'x-vms-session': session,
                'Content-Type': 'application/json',
            }),
            body: {'idnumber': idnumber },
          };

        return this.http.delete(url, options)
                        .toPromise();
    }
    public getAgeGroup() {
        return [
            {key: 'in', title: 'age_group.in'},
            {key: 't1', title: 'age_group.t1'},
            {key: 't2', title: 'age_group.t2'},
            {key: 'ps', title: 'age_group.ps'},
            {key: 'pk', title: 'age_group.pk'},
        ]
    }
    public getAssessment() {
        return [
            {key: 'i1', title: '0-6 months', titleKey: 'age_group.0_6'},
            {key: 'i2', title: '6-12 months', titleKey: 'age_group.6_12'},
            {key: 't1', title: '12-24 months', titleKey: 'age_group.12_24'},
            {key: 't2', title: '24-36 months', titleKey: 'age_group.24_36'},
            {key: 'ps', title: '36-48 months', titleKey: 'age_group.36_48'},
            {key: 'pk', title: '48-60 months', titleKey: 'age_group.48_60'},
        ]
    }
    public assignTeacher(username, clas) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });

        const url = `${this.apiRoot}/admin/teacher/addtoclass`;
        const body = {
            "username": username,
            "class": clas
         };         

        return this.http.post(url, body, { headers })
                        .toPromise();     
    }
    public assignChild(username, clas) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });

        const url = `${this.apiRoot}/admin/child/addtoclass`;
        const body = {
            "username": username,
            "class": clas
         };         

        return this.http.post(url, body, { headers })
                        .toPromise();     
    }
    public removeChildFromClass(username, clas) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });

        const url = `${this.apiRoot}/admin/child/removefromclass`;
        const body = {
            "username": username,
            "class": clas
         };         

        return this.http.post(url, body, { headers })
                        .toPromise();     
    }
    public removeTeacherFromClass(username, clas) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });

        const url = `${this.apiRoot}/admin/teacher/removefromclass`;
        const body = {
            "username": username,
            "class": clas
         };         

        return this.http.post(url, body, { headers })
                        .toPromise();     
    }
    public removeTeacher(username) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });

        const url = `${this.apiRoot}/admin/teacher`;
 
        const options = {
            headers: new HttpHeaders({
                'x-vms-session': session,
                'Content-Type': 'application/json',
            }),
            body: {"username": username },
        };

        return this.http.delete(url, options)
                        .toPromise();   

    }

    public removeChild(username) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });

        const url = `${this.apiRoot}/admin/child`;

        const options = {
            headers: new HttpHeaders({
                'x-vms-session': session,
                'Content-Type': 'application/json',
            }),
            body: {"username": username },
        };     

        return this.http.delete(url, options)
                        .toPromise();   
    }

    public async getReflections(school: string, week: string) {

        let element = this.reflectionsCache.find(x=>x.school === school && x.week === week);

        if (element) {
            return Promise.resolve(element.value);
        } else {   
            // create session header
            const session = this.appState.restore('session');
            const headers = new HttpHeaders({ 'x-vms-session': session });

            // call API to get list of teacher reflections
            // for specific class and week
            const url = `${this.apiRoot}/admin/reflection`;
            let params = new HttpParams();
            params = params.append('school', school);
            params = params.append('week', week);

            return this.http.get<Reflection[]>(url, { params, headers })
                        .toPromise() 
                        .then((reflections: Reflection[]) =>{
                             
                            this.reflectionsCache.push({
                                school:   school,
                                week: week,
                                value: reflections
                            });
                            return reflections;
                        })
        }


    }

    public async getReflection(school: string, week: string, day: string, clas: string, teacher: string) {

        return this.getReflections(school, week)
                    .then((reflections) => {

                        const dailyReflection = reflections.find((r) => r.class === clas && r.teacher === teacher && r.day === day.toString());
                        return !!dailyReflection ? dailyReflection.reflection : null
                    });
    }

    public deleteAssessment(id: string, student: string){

        const session = this.appState.restore('session');
        const url = `${this.apiRoot}/admin/assessment`;

        const options = {
            headers: new HttpHeaders({
                'x-vms-session': session,
                'Content-Type': 'application/json',
            }),
            body: {'id': id, 'student': student },
          };

        return this.http.delete(url, options)
                        .toPromise();
    }


    public editAssessment(student: string, id: string, date, assessment){

        const session = this.appState.restore('session');
        const url = `${this.apiRoot}/admin/assessment`;

        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });
        const body = {
            'student': student, 
            'id': id, 
            'date': date, 
            'assessment': assessment 
            };
            

        return this.http.put(url, body, { headers })
                        .toPromise();    
    }

    public getAssessmentDetail(student: string, id: string) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });
        // call API to get list of classes
        const url = `${this.apiRoot}/admin/assessment/details`;
        let params = new HttpParams();

        params = params.append('student', student);
        params = params.append('id', id);

        return this.http.get(url, {params, headers})
                        .toPromise()
                        .then((details: IAssessmentDetails) =>{

                            return details;
                        });      

    }

    public getAssessmentByUsername(username) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });
        // call API to get list of classes
        const url = `${this.apiRoot}/admin/student/assessment`;
        let params = new HttpParams();

        params = params.append('username', username);

        return this.http.get(url, {params, headers})
                        .toPromise()
                        .then((assessments: IAssessmentDetails[]) =>{

                            return assessments;
                        });      

    }

    public getUserInfo(username){
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });
        
        const url = `${this.apiRoot}/user/info`;
        let params = new HttpParams();

        params = params.append('username', username);

        return this.http.get(url, {params, headers})
                        .toPromise()
                        .then((user: any) => {

                            return user;
                        })
    }

    public addTeachersImport(school, users) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });

        const url = `${this.apiRoot}/admin/teacher/import`;
        // email for teachers is constanta
        // users dont enter email for teacher
        users.forEach((x) => {
            x.email =  this.setTeacherEmail();
        })

        const body = {
            'school': school,
            'users': users
         };       

        return this.http.post(url, body, { headers })
                        .toPromise()
                        .then((results: any) => {

                            return results;
                        })      

    }


    public addChildrenImport(school, users, clas?:string) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });

        const url = `${this.apiRoot}/admin/child/import`;

        // email for children is constanta
        // users dont enter email for child
        users.forEach((x) => {
            x.email =  this.setChildEmail();
        })

        const body = {
            'school': school,
            'class': clas,
            'users': users
         };
         

        return this.http.post(url, body, { headers })
                        .toPromise()
                        .then((results: any) => {

                            return results;
                        })      

    }

    public belive() {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session,  'Content-Type': 'application/json' });

        // get 2BeLive(training) "magic link"
        const url = `${this.apiRoot}/admin/integrations/belive`;
        return this.http.post(url, null, { headers })
                        .toPromise()
                        .then((results: any) => results.magic_link);
    }


    // new reflections methods 2024

    public getTeachersByAgeAndSchool(schoolId: string, ageId: string) {
       
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });
    
        const url = `${this.apiRoot}/school/teacher`;
        let params = new HttpParams();
        params = params.append('school', schoolId);
        params = params.append('age', ageId);
    
        return this.http.get(url, { params, headers })
                        .toPromise()
                        .then((teachers: ITeacher[]) => {
                            return teachers;
                        });
    }

    public getActiveClassesByAgeSchoolAndTeacher(school: string, age: string, teacher?: string) {
        
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });
    
        const url = `${this.apiRoot}/school/class`;
        let params = new HttpParams();
        params = params.append('school', school);
        params = params.append('age', age);
    
        if (teacher) {
            params = params.append('teacher', teacher);
        }
    
        return this.http.get(url, { params, headers })
                        .toPromise()
                        .then((classes: IClass[]) => {
                            return classes;
                        });
    }

    public getReflectionsByTeacherClassAreaWeekDay(teacher: string, classId: string, area: string, week: number, day: number, ) {
     
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });
    
        const url = `${this.apiRoot}/admin/reflection`;
        let params = new HttpParams();
        params = params.append('teacher', teacher);
        params = params.append('class', classId);
        params = params.append('area', area);
        params = params.append('week', week.toString());
        params = params.append('day', day.toString());
    
        return this.http.get(url, { params, headers })
                        .toPromise()
                        .then((reflections: any[]) => {
                            return reflections;
                        })
                        .catch((error) => {
                            console.error('Error getting reflections: ', error);
                        });
    }

    public getCommentsBySchoolAgeTeacherClassAreaWeekDay( schoolId: string, ageId: string, teacherId?: string, classId?: string, area?: string, week?: number,  day?: number ) {
    
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });
    
        const url = `${this.apiRoot}/admin/reflection-comment`;
        let params = new HttpParams();
    
        params = params.append('school', schoolId);
        params = params.append('age', ageId);
    
        if (teacherId) {
            params = params.append('teacher', teacherId);
        }
        if (classId) {
            params = params.append('class', classId);
        }
        if (area) {
            params = params.append('area', area);
        }
        if (week !== null) {
            params = params.append('week', week.toString());
        }
        if (day !== null) {
            params = params.append('day', day.toString());
        }
    
        return this.http.get(url, { params, headers })
                        .toPromise()
                        .then((comments: any[]) => {
                            return comments;
                        })
                        .catch((error) => {
                            console.error('Error getting comments: ', error);
                        });
    }

    public getReflectionSummary(schoolId: string, ageId: string, area: string, teacherId?: string, classId?: string, week?: number, day?: number) {

        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });
    
        const url = `${this.apiRoot}/admin/reflection-summary`;
        let params = new HttpParams();
    
        params = params.append('school', schoolId);
        params = params.append('age', ageId);
        params = params.append('area', area);
    
        if (teacherId) {
            params = params.append('teacher', teacherId);
        }
        if (classId) {
            params = params.append('class', classId);
        } 
        if (week !== null) {
            params = params.append('week', week.toString());
        }
        if (day !== null) {
            params = params.append('day', day.toString());
        }
    
        return this.http.get(url, { params, headers })
                        .toPromise()
                        .then((summary: any[]) => {
                            return summary;
                        })
                        .catch((error) => {
                            console.error('Error getting reflection summary: ', error);
                        });
    }
}


interface Reflection {
    week: string,
    day: string,
    reflection: string,
    class: string,
    age_group: string,
    teacher: string
}
