import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbDropdownModule, NgbModule, NgbNavModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CleverLoginComponent } from './auth/clever/clever.component';
import { LoginComponent } from './auth/login/login.component';
import { AfterLoginComponent } from './auth/after/after-login.component';
import { SamlInitComponent } from './auth/saml/init/saml-init.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClickOutsideModule } from 'ng-click-outside';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Interceptor401 } from './services/interceptors/401/interceptor';

import * as editor from './editor/index';
import * as teacher from './teacher/index';
import * as admin from './admin/index';
import * as view from './views/index';
import * as shared from './shared/index';
import * as services from './services/index';
import { DailyLessonTableComponent } from './views/lesson-plans/tables/daily-lesson-table/daily-lesson-table.component';
import { WeeklyLessonTableComponent } from './views/lesson-plans/tables/weekly-lesson-table/weekly-lesson-table.component';
import { FilterPipe } from './admin/classmanagement/FilterPipe';
import { FilterStudentAssessmentPipe } from './admin/assessment/students/FilterStudentAssessment';
import { AdminFilterReflection } from './admin/reflection/AdminFilterReflection';
import { RouterExtService } from './services/RouterExtService';
import { SearchPipe } from './teacher/assessment/students/SearchPipe';
import { SubTileComponent } from './teacher/guide/sections/sub-tile/sub-tile.component';
import { ContentComponent } from './teacher/guide/sections/content/content.component';
import { from } from 'rxjs';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { EmailVerificationComponent } from './auth/email-verification/email-verification.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AlignmentComponent } from './teacher/standards/alignment/alignment.component';
import { StateStandardsComponent } from './teacher/standards/state-standards/state-standards.component';
import { UserProfileComponent } from './auth/user-profile/user-profile.component';
import { ParentComponent } from './parent/parent.component';
import { ColorChromeModule  } from 'ngx-color/chrome';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { Reflection24Component } from './admin/reflection24/reflection24.component';
import { AnalyticsComponent } from './admin/reflection24/analytics/analytics.component';
import { FormsComponent } from './admin/reflection24/forms/forms.component';
import { CommentsComponent } from './admin/reflection24/comments/comments.component';
import { ProgressChartComponent } from './shared/progress-chart/progress-chart.component';
export class CustomHammerConfig extends HammerGestureConfig  {
  overrides = <any>{
      'pinch': { enable: false },
      'rotate': { enable: false }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CleverLoginComponent,
    AfterLoginComponent,
    SamlInitComponent,
    shared.MenuComponent,
    shared.AgesComponent,
    shared.FooterComponent,
    shared.ProgressChartComponent,
    shared.DatepickerComponent,
    shared.LoaderComponent,

    // pipes
    FilterPipe, SearchPipe, AdminFilterReflection, FilterStudentAssessmentPipe,

    // Editor
    editor.EditorComponent,
    editor.ResourcesComponent,
    editor.FiltersPanelComponent,
    editor.ActivityFilterComponent,
    editor.LanguageFilterComponent,
    editor.AgeFilterComponent,
    editor.DayFilterComponent,
    editor.ModeFilterComponent,
    editor.TypeFilterComponent,
    editor.WeekFilterComponent,
    editor.ToastsComponent,

    // Views
    view.DailyActivityViewComponent,
    view.LessonPlansViewComponent,
    view.DailyLessonTableComponent,
    view.WeeklyLessonTableComponent,
    view.DailyStandartsComponent,
    view.MaterialsViewComponent,
    view.HomeConnectionComponent,

    // Teacher
    teacher.TeacherRootComponent,
    teacher.ModeComponent,
    teacher.AssessmentComponent,
    teacher.StudentsAssessmentComponent,
    teacher.EditAssessmentComponent,
    teacher.DailyActivitiesComponent,
    teacher.DailyActivityComponent,
    teacher.GuideComponent,
    teacher.ResourcesTeacherComponent,
    teacher.ConnectionsComponent,
    teacher.LessonPlansComponent,
    teacher.MaterialComponent,
    teacher.MaterialsWeeklyComponent,
    teacher.ReflectionsComponent,
    teacher.ClassReflectionsComponent,
    teacher.StandardsComponent,
    teacher.DailyStandardsComponent,
    teacher.FiltersComponent,
    teacher.ContentComponent,
    teacher.SubTileComponent,
    teacher.SearchComponent,
    WeeklyLessonTableComponent,

    // admin
    admin.AdminComponent,
    admin.AdminModeComponent,
    admin.ClassManagementComponent,
    admin.EditClassComponent,
    admin.AddClassComponent,
    admin.UserComponent,
    admin.AssignedToClassroomComponent,
    admin.FormUserComponent,
    admin.EditUserComponent,
    admin.UserManagementComponent,
    SubTileComponent,
    ContentComponent,
    admin.UserManagementComponent,
    admin.ListComponent,
    admin.FilterBySchoolComponent,
    admin.UserNewManagementComponent,
    admin.EditUserManagementComponent,
    admin.AdminAssesmentComponent,
    admin.AdminReflectionComponent,
    admin.Reflection24Component,
    admin.AdminBrandComponent,
    admin.AdminClassReflectionComponent,
    admin.AdminAssesmentStudentsComponent,
    admin.AdminEditAssesmentComponent,
    admin.AdminAssesmentStudentComponent,
    admin.ImportComponent,
    admin.ImportChildComponent,
    admin.ImportTeacherComponent,


    ForgotPasswordComponent,
    EmailVerificationComponent,
    AlignmentComponent,
    StateStandardsComponent,
    UserProfileComponent,
    ResetPasswordComponent,
    ParentComponent,
    Reflection24Component,
    AnalyticsComponent,
    FormsComponent,
    CommentsComponent,
    ProgressChartComponent
  ],
  imports: [
    BrowserModule,
    HammerModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    NgbModule,
    NgbNavModule,
    ClickOutsideModule,
    NgSelectModule,
    PdfViewerModule,
    ReactiveFormsModule,
    ColorChromeModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
    
  ],
  providers: [
    services.TeacherService,
    services.AppState,
    services.ContentService,
    services.ToastService,
    services.SwipeService,
    services.AssessmentService,
    services.SessionService,
    services.AdminService,
    services.MessagesService,
    services.PreviousRouteService,
    services.EmailVerificationService,
    services.ApiService,
    services.StandardsService,
    RouterExtService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomHammerConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor401,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}