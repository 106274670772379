import { Component, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TeacherService } from 'src/app/services/teacher.service';
import { ContentService } from 'src/app/services/content.service';
import { AppState, IResource } from 'src/app/services';

@Component({
    selector: 'teacher-daily-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss']
})
export class DailyActivityComponent implements OnChanges {
    
    @Input() public age: string = null;
    @Input() public week: string = null;
    @Input() public day: string = null;
    @Input() public activity: string = null;

    public template: string = null;
    public resources: IResource[];
    public missing: boolean = false;
    public loader: boolean = false;
    public classes: any;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private teacherService: TeacherService,
                private contentService: ContentService,
                private appState: AppState,
                private translateService: TranslateService) {
        // empty
    }

    public ngOnChanges() {

      this.appState.store('activity', this.activity, true);

      this.load(this.age, this.week, this.day, this.activity);
    } 

    public openConenctions() {

        this.router.navigate(['home/hc'], { relativeTo: this.activatedRoute.parent });
    }

    public openReflections() {
        if(this.classes.length == 1) {

            this.appState.store('area', this.activity, true);
            const _classID = this.classes[0].idnumber;
            this.router.navigate([`home/tr/${_classID}`], { relativeTo: this.activatedRoute.parent });
        } else{
            
            this.router.navigate(['home/tr'], { relativeTo: this.activatedRoute.parent });
        }
    }

    private load(age, week, day, activity) {
    
      const language = this.translateService.currentLang;

      this.loader = true;

      this.teacherService.getClasses().then((classes) => {
        this.classes = classes;
        this.classes = classes.filter( element => element.age_group == this.age)
      });

      this.contentService
          .getDailyActivities(language, age, week, day, activity)
          .catch((err) => {
            if (language !== 'en') {
                return this.contentService.getDailyActivities('en', age, week, day, activity)
            } else { 
                throw err;
            }
          })
          .then((data) => {

              this.missing = false;
              this.template = data.template;
              this.loader = false;
              this.resources = data.files.map<IResource>((file) => ({
                title: file.filename,
                data: file.url,
                type: file.type
              }));              
          })
          .catch((err) => {
              
              this.missing = true;
              this.template = null;
              this.loader = false;
          })
  }  
}
