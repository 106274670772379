import {Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import * as XLSX from 'xlsx';
import { MessagesService } from 'src/app/services/messages.service';
import { FormGroup } from '@angular/forms';
import { AppState } from 'src/app/services/app.service';
import { ActivatedRoute } from '@angular/router';
import { ImportComponent } from './import.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-import',
    templateUrl: './import.component.html',
    styleUrls: ['./import.component.scss'],
  })

export class ImportTeacherComponent extends ImportComponent implements OnInit {

    constructor(getMsgError: MessagesService,
        adminService: AdminService,
        appState: AppState,
        translateService: TranslateService,
        activatedRoute: ActivatedRoute) {
        super(getMsgError, adminService, appState, translateService, activatedRoute);
    }

    public ngOnInit(): void { 

        this.school = this.appState.restore('school');
    }

}