import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { TeacherService, StandardsService } from 'src/app/services';
import { IWeeklyTableData, IWeeklyTableActivity } from '../../lesson-plans.component';
import { TranslateService } from '@ngx-translate/core';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { Router } from '@angular/router';

@Component({
  selector: 'app-weekly-lesson-table',
  templateUrl: './weekly-lesson-table.component.html',
  styleUrls: ['./weekly-lesson-table.component.scss','../../../../teacher/teacher.component.scss'],
})
export class WeeklyLessonTableComponent implements OnInit {
  
  
  public language: string;
  public ageTitle: string;
  public stateName: string;

  public loader: boolean = true;

  public standardsData:Array<{ id: string; standards: string [] }> = [];
  public standardsNames: Array<{ id: string; name: string }> = [];

  @Input()
  public school: any = null;
  /**
 * Input `age parameter` from filters.
 */
  @Input()
  public age: string = null;

  /**
   * Input `week parameter` from filters.
   */
  @Input()
  public week: string = null;

  @Input()
  public tableData: IWeeklyTableData = {
    activities: [
      { id: 'st', sortBy: 1, title: null, items: [] },
      { id: 'pd', sortBy: 2, title: null, items: [] },
      { id: 'll', sortBy: 3, title: null, items: [] },
      { id: 'mm', sortBy: 4, title: null, items: [] },
      { id: 'ir', sortBy: 5, title: null, items: [] },
      { id: 'cg', sortBy: 6, title: null, items: [] }
    ],
  };

  @Output()
  public onSelect: EventEmitter<{ code: string }> = new EventEmitter();
  
  constructor(  private teacherService: TeacherService, 
                private router: Router,
                private translateService: TranslateService,
                private standardsService: StandardsService) {}

  ngOnInit(): void { 

    this.getAgeTitle(this.age);
    this.language = this.translateService.currentLang;

    if(this.router.url  != '/editor'){
      this.loadStandardsNames();
      this.loadStandards();
      
      this.stateName = this.teacherService.getStateName(this.school.p_state)
    } else{
      this.loader = false;
    }
  }

  public vmOnSelected(code: string) {
    this.onSelect.emit({ code });
  }

  public getAgeTitle(ageKey: string) {

    const age = this.teacherService.getAges().find((a) => a.key == ageKey);
    this.ageTitle = age.titleKey;
  }

  public showStandards(id: string): boolean {
    return !!this.standardsData.find(item => item.id === id)?.standards?.length;
}

  async loadStandards() {
    
    const requests = [];
    for (let activity of this.tableData.activities) {
        requests.push(
          this.standardsService
            .getLessonPlanStandards(this.school.p_state, this.age.toUpperCase(), this.language.toUpperCase(), activity.id.toUpperCase(), Number(this.week), 0)
            .then((standards) => {
               this.standardsData.push({ id: activity.id, standards: standards });
            })
        );
    }
    await Promise.all(requests);
    
    this.loader = false;
  }

  async loadStandardsNames() {

    const requests = [];
    for (let activity of this.tableData.activities) {
      requests.push(
        this.standardsService
          .getStandardName(this.school.p_state, this.age.toUpperCase(), this.language.toUpperCase())
          .then((standardName) => {
            this.standardsNames.push({ id: activity.id, name: standardName || this.translateService.instant('shared.not_available') });
          })
      );
    }
    await Promise.all(requests);
  }
}
