import {Component, OnInit } from '@angular/core';
import { MenuComponent } from '../../shared/menu/menu.component';
import { AdminService } from 'src/app/services/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from 'src/app/services';

@Component({
    selector: 'app-user-management',
    templateUrl: './usermanagement.component.html',
    styleUrls: ['./usermanagement.component.scss'],
  })

export class UserManagementComponent implements OnInit {

    public modes = [];
    public unknownPage: string = 'admin_home.user_management';
    public school: any;

    constructor(private adminService: AdminService,
                private router: Router,
                private appState: AppState,
                private activatedRoute: ActivatedRoute) {

    }

    public ngOnInit(): void {
        this.school = this.appState.restore('school');    
        this.modes = this.getModes();
        
    }
    public onModeChoose(mode:string): void {

      this.router.navigate([ mode ], { relativeTo: this.activatedRoute });
    }
    /** Get list of modes */
    private getModes() {

        return [
            { key: 'assignedchild', titleKey: 'admin_um.child_management' },
            { key: 'assignedteacher', titleKey: 'admin_um.teacher_management' }
        ]
    }
    public onChangeSchool(school) {
        this.school = school;
    }
}