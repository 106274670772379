import { Component, OnInit, SimpleChanges, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppState } from 'src/app/services/app.service';
import { TeacherService } from 'src/app/services/teacher.service';
import { ContentService } from 'src/app/services/content.service';
import { ToastService } from '../../../services/index';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'teacher-class-reflections',
  templateUrl: './class.reflections.component.html',
  styleUrls: ['./class.reflections.component.scss']
})
export class ClassReflectionsComponent implements OnInit {

  public title: string = 'Teacher Reflections';
  public classTitle: string = null;
  public school: any;

  public age: string = null;
  public week: number = null;  
  public day: number = null;
  public area: string = null;
  public language: string;

  public reflection: any[];
  public areas: any[];

  private classId: string;
  private version: string;
  
  public hasUnsavedChanges: boolean = false;
  public allQuestionsAreAnswered: boolean = false;
  
  public warning: boolean = false;
  public loader: boolean = false;

  constructor(private appState: AppState,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private contentService: ContentService,
              private teacherService: TeacherService,
              private toastService: ToastService,
              private translateService: TranslateService) {

  }

  ngOnInit(): void {
  
    this.language = this.translateService.currentLang;
    this.age = this.appState.restore('age');
    this.areas = this.teacherService.getActivitiesInAge(this.age)
    this.week = this.appState.restore('week');
    this.day = this.appState.restore('day');
    this.area = this.appState.restore('area');

    if (!this.age) {
      this.appState.store('age', 'in', true);
      this.age = this.appState.get('age');
    }

    if (!this.week) {
      this.appState.store('week', 1, true);
      this.week = this.appState.get('week');
    }

    if (!this.day) {
      this.appState.store('day', 1, true);
      this.day = this.appState.get('day');
    }

    // get class title
    this.classId = this.activatedRoute.snapshot.params['class'];
    this.teacherService
        .getClasses()
        .then((classes) => {
          this.classTitle = classes.find((item) => item.idnumber === this.classId).title;
        });
        
    this.school = this.appState.restore('school');

    this.load(this.week, this.day, this.area, this.classId);
    window.scrollTo(0, 0);
  }

  public onAreaClick(area: string){

      this.area = area;
      this.load(this.week, this.day, this.area, this.classId);
  }
  

  public onAnswerChanged() {
    this.hasUnsavedChanges = true;
    let count: number = 0;
    const questions = this.reflection.filter((question) => question.type != "OPEN_ENDED");
  
    questions.forEach((question) => {
      if (question.answers.some((answer) => answer.value == true)) count++
    });
  
    this.allQuestionsAreAnswered = count === questions.length;
  }

  public exit() {
      // return back to teacher reflections overview page
      history.back();
  }

  public dayChanged(day) {

    this.day = day;
    
    this.loader = true;
    this.load(this.week, this.day, this.area, this.classId);
  } 

  public weekChanged(week) {

    this.week = week;
    this.load(this.week, this.day, this.area, this.classId);
  }

  public goTo(type:string){
    
    if(this.day != 0){
      if (type == '+'){
        if (this.day == 5){
          this.day = 1;
          if (this.week == 52){
            this.week = 1;
          } else{
            this.week++;
          }
        } else{
          this.day++;
        }
      }
      if (type == '-'){
        if (this.day == 1){
          this.day = 5;
          if (this.week == 1){
            this.week = 52;
          } else{
            this.week--;
          }
        } else{
          this.day--;
        }
      }  
    } else{
      if(type == '+'){
        if(this.week == 52) {
          this.week = 1;
        } else{
          this.week++
        }
      }
      if(type == '-'){
        if(this.week == 1) {
          this.week = 52;
        } else{
          this.week--
        }
      }
    }
      
    this.appState.store('day',this.day, true);
    this.appState.store('week',this.week, true);
 
    this.load(this.week, this.day, this.area, this.classId);
  }

  public onSingleSelectChange(question: any, selectedAnswer: any): void {
    question.answers.forEach((answer: any) => {
      answer.value = false;
    });
  
    selectedAnswer.value = true;
    this.onAnswerChanged();
  }

  public submit() {
    const reflection = this.reflection.map((question) => {
      return {
        id: question.id,
        text: question.value,
        answers: question.type === 'LIST' ? question.answers.filter((answer) => answer.value).map((answer) => answer.id) 
               : question.type === 'MULTISELECT' ? question.answers.filter((answer) => !!answer.value).map((answer) => answer.id) : null
      };
    });
  
    this.teacherService
      .saveReflections(this.classId, this.week, this.day, this.area, reflection, this.version)
      .then(() => {
        this.warning = false;
        this.hasUnsavedChanges = false;
        this.exit();
      })
      .catch((error) => {
        console.error(error);
        const info = this.translateService.instant('shared.save_failed');
        this.toastService.showDanger(info);
      });
  }

  /**
   * Load reflections per day.
   * Before loading content check if reflection was already submitted
   * @param week week number
   * @param day day number
   * @param area activity id
   * @param classId class identifier
   */
  private async load(week: number, day: number, area: string, classId: string) {
 
    const language = this.translateService.currentLang;
    this.allQuestionsAreAnswered = false;
    this.hasUnsavedChanges = false;
    this.loader = true;
    // load reflection content
    this.contentService
        .getReflections(language, area)
        .catch((err) => {
          if (language !== 'en') {
              return this.contentService.getReflections('en', area)
          } else { 
              throw err;
          }
        })
        .then((content) => {

            this.reflection = content.body.map( (_reflection) => ({
              answers: (_reflection.answers || []).map((answer) => ({
                id: answer.id,
                text: answer.text,
                value: false,
              })),
              id: _reflection.id,
              type: _reflection.type,
              text: _reflection.text.replace('%3Cbr%20/%3E',' ').replace('%3Cbr%20/%3E%3Cbr%20/%3E',' ').replace('%3Cbr%20/%3E%3Cbr%20/%3E',' ')
            }));

            this.version = content.version; 
            
            // try loading previously saved reflection
            this.teacherService
                .getReflection(classId, week, day, this.area)
                .then((stored) => {

                  if (!!stored) {
                    
                    const savedAnswers = JSON.parse(stored);

                    savedAnswers.forEach((savedAnswer: any) => {
                      const question = this.reflection.find((q: any) => q.id === savedAnswer.id);
              
                      if (question) {
                        if (savedAnswer.text)  question.value = savedAnswer.text;
                        
                        if (savedAnswer.answers && question.answers) {
                          savedAnswer.answers.forEach((selectedId: string) => {
                            const answer = question.answers.find((a: any) => a.id === selectedId);
                            if (answer) answer.value = true;
                          });
                        }
                      }
                    });
                  }
                  
                  this.loader = false;                  
                }) 
                .catch(() => {
                  this.loader = false;
              })
        });
  }
}
