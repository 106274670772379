import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public currentYear;

  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

  public getCopyrite(){

    let _copyright = this.translateService.instant('shared.copyright');
    let result =  _copyright.replace('2024', this.currentYear);
    return  result;
  }
}
