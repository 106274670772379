import { Injectable } from "@angular/core";
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable()
export class StandardsService {

    private standardsCache: any = {};
    private standardNamesCache: any = {};

    constructor(private apiService: ApiService) {
        // empty        
    }

    /**
     * Get standard names
     * @param state state code
     * @param age age code
     * @param language language code
     * @returns 
     */
    public async getStandardName(state: string, age: string, language: string): Promise<string> {

        const cached = this.standardNamesCache[state];
        if (!!cached) {
            const standardNames = await cached;
            return standardNames.find((item: any) => item.age === age && item.language === language)?.standard_name;
        } else {

            this.standardNamesCache[state] = new Promise((resolve, reject) => {

                const url = '/teacher/standardnames';
                const params = new HttpParams()
                    .append('state', state);

                this.apiService.get(url, null, params)
                    .then(resolve)
                    .catch(reject);
            });

            return this.getStandardName(state, age, language);
        }
    }

    /**
     * Get standards list from cache or API
     * @param state state code
     * @param age age code
     * @param language language code
     * @param area area code
     * @param week week number
     * @param day day number
     * @returns 
     */
    private async getStandardsCore(state: string, age: string, language: string, area: string, week: number, day: number = 0): Promise<IStandard[]> {

        const key = `${state}-${age}-${language}-${week}`;
        const cached = this.standardsCache[key];
        if (!!cached) {
            const standards = await cached;
            return standards.find((item: any) => item.day === day && item.area === area)?.standards;
        } else {

            this.standardsCache[key] = new Promise((resolve, reject) => {

                const url = '/teacher/standards';
                const params = new HttpParams()
                    .append('state', state)
                    .append('age', age)
                    .append('language', language)
                    .append('week', week.toString());

                this.apiService.get(url, null, params)
                    .then(resolve)
                    .catch(reject);
            });

            return this.getStandardsCore(state, age, language, area, week, day);
        }
    }

    /**
     * Get standards list
     * @param state state code
     * @param age age code
     * @param language language code
     * @param area area code
     * @param week week number
     * @param day day number
     * @returns 
     */
    public async getStandards(state: string, age: string, language: string, area: string, week: number, day: number = 0): Promise<string[]> {

        const standards = await this.getStandardsCore(state, age, language, area, week, day);
        return this.getStandardsText(standards);
    }

    /**
     * Get daily standards
     * @param state state code
     * @param age age code
     * @param language language code
     * @param area area code
     * @param week week number
     * @param day day number
     * @returns 
     */
    public async getDailyStandards(state: string, age: string, language: string, area: string, week: number, day: number = 0): Promise<string[]> {

        const standards = await this.getStandardsCore(state, age, language, area, week, day);
        const dailyStandards = standards.filter((item: any) => item.daily_activity);
        return this.getStandardsText(dailyStandards);
    }

    /**
     * Get lesson plan standards
     * @param state state code
     * @param age age code
     * @param language language code
     * @param area area code
     * @param week week number
     * @param day day number
     * @returns 
     */
    public async getLessonPlanStandards(state: string, age: string, language: string, area: string, week: number, day: number = 0): Promise<string[]> {

        const standards = await this.getStandardsCore(state, age, language, area, week, day);
        const lessonPlanStandards = standards.filter((item: any) => item.lesson_plan);
        return this.getStandardsText(lessonPlanStandards);
    }

    /**
     * Get standards text
     * @param standards list of standards 
     * @returns 
     */
    private getStandardsText(standards: IStandard[]): string[] {

        // combine standard properties into a single string
        // and remove duplicates
        return standards.map((item) => {

            
            const properties = ['code', 'domain', 'strand', 'age_range', 'standard', 'benchmark'];
            const response = properties.map((property) => item[property]).filter((value) => !!value).join(': ');

            // trim extra spaces and remove trailing colon
            return response.trim().replace(/:$/, '');
        })
        .filter((item, index, arr) => arr.indexOf(item) === index);
    }
}

export interface IStandard {
    age_range: string,
    code: string,
    benchmark: string,
    domain: string,
    standard: string,
    strand: string
}