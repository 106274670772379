import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ITableRow } from '../../lesson-plans.component';
import { TranslateService } from '@ngx-translate/core';
import { TeacherService, StandardsService } from 'src/app/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-daily-lesson-table',
  templateUrl: './daily-lesson-table.component.html',
  styleUrls: ['./daily-lesson-table.component.scss', '../../../../teacher/teacher.component.scss'],
})
export class DailyLessonTableComponent implements OnInit, OnChanges {
 
  
  public standardsData:Array<{ id: string; day: number; standards: string [] }> = [];
  public standardsNames: Array<{ id: string; name: string }> = [];

  public loader: boolean = true;

  public ageTitle: string;
  public language: string;
  public stateName: string;

  @Input()
  public school: any = null;

  /**
 * Input `age parameter` from filters.
 */
  @Input()
  public age: string = null;

  /**
   * Input `week parameter` from filters.
   */
  @Input()
  public week: string = null;


  @Input()
  public tableData: Array<ITableRow> = [
    {
      id: 'st',
      sortBy: 1,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    },
    {
      id: 'll',
      sortBy: 2,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    },
    {
      id: 'pd',
      sortBy: 3,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    },
    {
      id: 'mm',
      sortBy: 4,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    },

    {
      id: 'ir',
      sortBy: 5,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    },
    {
      id: 'cg',
      sortBy: 6,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    }
  ];

  @Output()
  public onSelect: EventEmitter<{ code: string; day: number }> = new EventEmitter();

  constructor(  private teacherService: TeacherService, 
                private router: Router,
                private translateService: TranslateService,
                private standardsService: StandardsService) {}

  public ngOnInit(): void {

    this.getAgeTitle(this.age) 
    this.language = this.translateService.currentLang;
    if(this.router.url  != '/editor'){
      this.loadStandardsNames();
      this.loadStandards();
      this.stateName = this.teacherService.getStateName(this.school.p_state)
    } else{
      this.loader = false;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {}

  async loadStandards() {
    const requests = [];
    for (let activity of this.tableData) {
      for (let day = 1; day <= 5; day++) {
        requests.push(
          this.standardsService
            .getLessonPlanStandards(this.school.p_state, this.age.toUpperCase(), this.language.toUpperCase(), activity.id.toUpperCase(), Number(this.week), Number(day) ?? 0)
            .then((standards) => {
              this.standardsData.push({
                id: activity.id,
                day: day,
                standards: standards,
              });
            })
        );
      }
    }
    await Promise.all(requests); 
    this.loader = false;
  }

  async loadStandardsNames() {
    const requests = [];
  
    for (let activity of this.tableData) {
      requests.push(
        this.standardsService
          .getStandardName(this.school.p_state, this.age.toUpperCase(), this.language.toUpperCase())
          .then((standardName) => {
            this.standardsNames.push({
              id: activity.id,
              name: standardName || this.translateService.instant('shared.not_available')
            });
          })
      );
    }
  
    await Promise.all(requests); 
  }

  public vmOnSelected(code: string, day: number): void {
    day = day + 1;
    this.onSelect.emit({ code, day });
    window.scrollTo(0, 0);
  }

  public getAgeTitle(ageKey: string) {

    const age = this.teacherService.getAges().find((a) => a.key == ageKey);
    this.ageTitle = age.titleKey;
  }
  
  public showStandards(){
    return this.standardsNames.some( (item) => !!item.name) && this.standardsData.some( (item) => item.standards.length > 0)
  }
}
