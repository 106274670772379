import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/services/app.service';

@Injectable()
export class ApiService {

    private apiRoot: string = environment.api;

    constructor(private http: HttpClient,
                private appState: AppState) {
        // empty        
    }

    public get(url: string, headers?: HttpHeaders, params?: HttpParams): Promise<any> {
        url = this.apiRoot + url;
        headers = this.addSessionHeader(headers);
        return this.http.get<any[]>(url, { params, headers }).toPromise()
    }

    public post(url: string, body: any, headers?: HttpHeaders, params?: HttpParams): Promise<any> {
        url = this.apiRoot + url;
        headers = this.addSessionHeader(headers);
        return this.http.post(url, body, { headers, params }).toPromise();
    }

    public delete(url: string, body: any, headers?: HttpHeaders, params?: HttpParams): Promise<any> {
        url = this.apiRoot + url;
        headers = this.addSessionHeader(headers);
        return this.http.delete(url, { params, headers }).toPromise();
    }

    // add session header
    private addSessionHeader(headers: HttpHeaders): HttpHeaders {

        // get session from appState
        const session = this.appState.restore('session');

        // add session header and return
        if (!headers) headers = new HttpHeaders();
        return headers.set('x-vms-session', session);
    }

}