import { Component, OnInit } from '@angular/core';
import { IClass } from 'src/app/interfaces/IClass';
import { ISchool } from 'src/app/interfaces/ISchool';
import { ITeacher } from 'src/app/interfaces/ITeacher';
import { AdminService, TeacherService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/services/app.service';
import { ContentService } from 'src/app/services/content.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})

export class FormsComponent implements OnInit {

  public unknownPage: string = 'admin_tr.completed_forms';
  public day: number = null;
  public week: number = null; 
  public school: ISchool; 

  public content: any[];
  public answers: any[];
  public content_body: any[];
  public loading: boolean = false;
  public disabled: boolean = false;

  // filters
  public listAgeGroups: {key: string, title: string}[];
  public listClasses: IClass[];
  public listTeachers: ITeacher[];
  public weeks: Number[] = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52]
  public days: Number[] = [1,2,3,4,5]
  
  public currentTeacher: ITeacher = null;
  public currentAgeGroup: {key: string, title: string} = null;
  public currentClass: IClass = null;

  constructor(private appState: AppState, 
    private contentService: ContentService, 
    private translateService: TranslateService,
    private adminService: AdminService,
    private cdr: ChangeDetectorRef)   { 

  } 

  ngOnInit(): void {

    this.loading = true;  
    this.listAgeGroups = this.adminService.getAgeGroup();

    this.school = this.appState.restore('school');
    this.day = 1;
    this.week= 1;
    setTimeout(() => {
      this.restoreFilters()
    }, 700);
  }

  public restoreFilters(){

    const _currentAgeGroup = this.appState.restore('currentAgeGroup') || null;

    if(_currentAgeGroup) {

      this.currentAgeGroup = this.listAgeGroups.find( ageGroup => ageGroup.key ===  _currentAgeGroup.key) || null; 

      if(this.currentAgeGroup) {
      
        this.getTeachers(this.school.idnumber, this.currentAgeGroup.key, true)
      }
    }
    
    this.currentClass = this.appState.restore('currentClass') || null;
    this.day = this.appState.restore('day') || this.days[0];
    this.week = this.appState.restore('week') || this.weeks[0];
    this.loading = false;
    this.cdr.detectChanges();
  }

  public getAgeGroup(age_group: string) {

    let age = this.adminService.getAgeGroup().find((x) => x.key === age_group);
    return age ? age.title : null;
  }

  public onChangeSchool(event: ISchool) {

    if (event) {
      this.school = event;
      this.currentAgeGroup = null;
      this.currentTeacher = null;
      this.currentClass = null;
      this.day = 1;
      this.week = 1;
      this.content = null;

      if (this.school && this.currentAgeGroup) {
        // Перевірка перед доступом до ключа
        if (this.currentAgeGroup.key) {
          this.getTeachers(this.school.idnumber, this.currentAgeGroup.key, false);
        }
      } 
    }
  }

  private getTeachers(schoolId, ageId, type:boolean): void {

    this.loading = true;
    this.adminService.getTeachersByAgeAndSchool(schoolId, ageId).then((teachers: ITeacher[]) => {
    this.listTeachers = Object.assign([], teachers.sort((a, b) => (a.firstname + a.lastname < b.firstname + b.lastname ? -1 : 1)));  
    this.loading = false;

    if(type){
      const _currentTeacher = this.appState.restore('currentTeacher') || null;
      if(_currentTeacher && _currentTeacher.firstname != 'All Teachers') {
        this.currentTeacher = this.listTeachers.find( teacher => teacher.username ===  _currentTeacher.username);
        this.getClasses()
      }
    }
    }).catch((error) => {
        console.log('Error get teachers: ', error);
    });  
  }

  private getClasses(){

    this.loading = true;
    this.adminService.getActiveClassesByAgeSchoolAndTeacher(this.school.idnumber, this.currentAgeGroup.key, this.currentTeacher.username).then((classes: IClass[]) => {
      
      this.listClasses = Object.assign([], classes.sort((a, b) => (a.name < b.name ? -1 : 1)));  
      const _currentClass = this.appState.restore('currentClass') || null;

      if(_currentClass){
        this.currentClass =  this.listClasses.find( _class => _class.idnumber ===  _currentClass.idnumber) 
      } else{  
        if(this.listClasses.length == 1){
          this.currentClass = this.listClasses[0];
        }
      }

      this.loading = false;
    })
  }

  public onChange(type: string){

    if(type == 'ageGroup'){

      this.currentClass = null;
      this.content = null;
      this.currentTeacher = null;
      this.appState.store('currentAgeGroup',this.currentAgeGroup,true);
      this.appState.remove('currentTeacher');
      this.appState.remove('currentClass');
      this.getTeachers(this.school.idnumber, this.currentAgeGroup.key, false)
    }
    if(type == 'teacher') {
      
      this.currentClass = null;
      this.content = null;
      this.appState.store('currentTeacher', this.currentTeacher,true);
      this.appState.remove('currentClass');
      this.getClasses()
    }
    if(type == 'class') {

      this.content = null;
      this.appState.store('currentClass', this.currentClass, true); 
    }
    if(type == 'week') {

      this.content = null;
      this.appState.store('week', this.day, true);
    }
    if(type == 'day') {

      this.content = null;
      this.appState.store('day', this.day, true);
    }
  }

  public load() {

    this.loading = true; 
    this.disabled = true; 

    this.adminService.getReflectionsByTeacherClassWeekDay(this.currentTeacher.username, this.currentClass.idnumber, this.week, this.day)
        .then((answers: any[]) => {

            this.answers = answers;
            this.getContent(this.currentAgeGroup.key, this.week.toString(), this.currentClass.idnumber, this.currentTeacher.username);
        })
        .catch((error) => {
          this.disabled = false; 
            console.error('Error receiving reflection: ', error);
        })
        .finally(() => {
          this.disabled = false; 
           this.loading = false; 
        });
  }

  /**
 * Load reflections per day.
 * Before loading content check if reflection was already submitted
 * @param age age code
 * @param week week number
 * @param day day number
 * @param classId class identifier
 */
  private async getContent(age: string, week: string, classId: string, teacher: string) {

    const language = this.translateService.currentLang;
   
    this.contentService.getReflections(language, age)
      .catch((err) => {
          return  language !== 'en' ? this.contentService.getReflections('en', age) : err;
      })
      .then((content) => {
            this.content = content.body;
       
            if (this.answers && this.answers[0] && this.answers[0].reflection) {
              let reflections = JSON.parse(this.answers[0].reflection);
              this.updateContentWithReflections(reflections, this.content);
            } 
        });
  }

  private updateContentWithReflections(reflections: any[], content: any) {

    reflections.forEach(reflection => {

        let question = content.find(q => q.id === reflection.id);
        
          if (question.type === 'LIST') {
            reflection.answers?.forEach(answerId => {
              let answer = question.answers.find(a => a.id === answerId);
              if (answer) answer.value = true; 
            });
          }
  
          if (question.type === 'OPEN_ENDED') {
            question.value = reflection.text;
          }
          
          this.disabled = false; 
          this.loading = false;
    });
  }

  public goTo(type:string){
    if (type == '+'){
      if (this.day == 5){
        this.day = 1;
        if (this.week == 52){
          this.week = 1;
        } else{
          this.week++;
        }
      } else{
        this.day++;
      }
  }
  if (type == '-'){
    if (this.day == 1){
      this.day = 5;
      if (this.week == 1){
        this.week = 52;
      } else{
        this.week--;
      }
    } else{
      this.day--;
    }
  }  
  
  this.appState.store('day',this.day, true);
  this.appState.store('week',this.week, true);
  this.load()
  }
}
