import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/services/app.service';
import { TeacherService } from 'src/app/services/teacher.service';

const xml2js = require('xml-js');

@Injectable()
export class ContentService {
  private contentRoot: string = environment.content;
  private apiRoot: string = environment.api;

  constructor(private http: HttpClient, private appState: AppState, private teacherService: TeacherService) {
    // empty
  }

  /**
   * Read content folder template and resources.
   * If not found returns code 403
   * @param id content folder id
   * @param parse parse template
   */
  private async getContent(id: string, parse: boolean) {

    // for editor usage ( identified by parse = false )
    // prevenmt caching of files by adding random query parameter
    const noCache = !parse ? `?t=${Date.now()}` : '';

    // retrieve info.xml
    const infoUrl = `${this.contentRoot}/${id}/info.xml` + noCache;
    const infoXml = await this.http.get(infoUrl, { responseType: 'text', withCredentials: true }).toPromise();

    // read info.xml resources
    // find all files included in content folder
    const xml = xml2js.xml2js(infoXml);
    const root = xml.elements[0];
    const version = root.attributes.version;

    let files: { filename: string; url: string, type: string }[];
    files = root.elements
      .filter((x) => x.name === 'file')
      .map((x) => {
        const filename = x.elements[0].text;

        const type: string = filename.slice(filename.lastIndexOf('.') + 1);

        return {
          filename,
          url: `${this.contentRoot}/${id}/${filename}` + noCache,
          type: type === 'jpg' ? 'image/jpeg' : type === 'png' ? 'image/png' : type === 'mp4' ? 'video/mp4' : type === 'pdf' ? 'application/pdf' : type,
        };
      });

    // check if content folder contains template.xml
    // if yes than load the file
    let template: string = null;
    const hasTemplate = files.some((file) => file.filename === 'template.xml');
    if (hasTemplate) {
      const templateUrl = `${this.contentRoot}/${id}/template.xml` + noCache;
      template = await this.http.get(templateUrl, { responseType: 'text', withCredentials: true }).toPromise();

      // if parse is requested
      // than process the template
      if (parse) {
        template = xml2js.xml2js(template).elements[0];
      }
    }

    // get resource only files
    // excluding template and zip
    files = files.filter((file) => file.filename !== 'content.zip' && file.filename !== 'template.xml');

    return {
      content: id,
      files,
      template,
      version,
    };
  }

  public async getResources(files: Array<{ filename: string; url: string; type: string }>): Promise<Array<Blob>> {
    const promises: Array<Promise<any>> = files.map((_file) => this.http.get(_file.url, { responseType: 'blob', withCredentials: true }).toPromise());
    return await Promise.all(promises);
  }

  /**
   * Get lesson plans
   * If not found returns code 403
   * @param language resource language
   * @param age age group
   * @param week week number
   * @param parse parse template
   */
  public async getLessonPlans(language: string, age: string, week: string, parse: boolean = true) {
    const prefix = this.generatePrefix(language, age);
    const content = `${prefix}-LP-W${week}-00001`;
    return this.getContent(content, parse);
  }

  /**
   * Save lesson plans
   * @param language resource language
   * @param age age group
   * @param week week number
   * @param files resource files
   */
  public async saveLessonPlans(language: string, age: string, week: string, files: ContentResource[]) {
    const prefix = this.generatePrefix(language, age);
    const content = `${prefix}-LP-W${week}-00001`;
    const title = this.generateTitle(language, age, 'lp', week);
    return this.updateContent(content, title, files);
  }

  /**
   * Get materilas
   * If not found returns code 403
   * @param language resource language
   * @param age age group
   * @param week week number
   * @param parse parse template
   */
  public async getMaterials(language: string, age: string, week: string, day: string, parse: boolean = true) {

    const prefix = this.generatePrefix(language, age);
    const sectionDay = !!day ? `-D${day}` : '';
    const content = `${prefix}-MT-W${week}${sectionDay}-00001`;
    return this.getContent(content, parse);
  }

  /**
   * Save materials
   * @param language resource language
   * @param age age group
   * @param week week number
   * @param files resource files
   */
  public async saveMaterials(language: string, age: string, week: string, day: string, files: ContentResource[]) {

    const prefix = this.generatePrefix(language, age);
    const sectionDay = !!day ? `-D${day}` : '';
    const content = `${prefix}-MT-W${week}${sectionDay}-00001`;

    const title = this.generateTitle(language, age, 'mt', week, day);
    return this.updateContent(content, title, files);
  }

  /**
   * Get daily activities.
   * If not found returns code 403.
   * @param language resource language
   * @param age age group
   * @param week week number
   * @param day day number
   * @param activity activity identifier
   * @param parse parse template
   */
  public async getDailyActivities(language: string, age: string, week: string, day: string, activity: string, parse: boolean = true) {
    const prefix = this.generatePrefix(language, age);
    const sectionDay = !!day ? `-D${day}` : '';
    const content = `${prefix}-DA-W${week}${sectionDay}-${activity.toUpperCase()}-00001`;
    return this.getContent(content, parse);
  }

  /**
   * Save daily activities
   * @param language resource language
   * @param age age group
   * @param week week number
   * @param day day number
   * @param activity activity identifier
   * @param files resource files
   */
  public async saveDailyActivities(language: string, age: string, week: string, day: string, activity: string, files: ContentResource[]) {
    const prefix = this.generatePrefix(language, age);
    const sectionDay = !!day ? `-D${day}` : '';
    const content = `${prefix}-DA-W${week}${sectionDay}-${activity.toUpperCase()}-00001`;
    const title = this.generateTitle(language, age, 'da', week, day, activity);
    return this.updateContent(content, title, files);
  }

  /**
   * Get daily standards.
   * If not found returns code 403.
   * @param language resource language
   * @param age age group
   * @param week week number
   * @param day day number
   * @param activity activity identifier
   * @param parse parse template
   */
  public async getDailyStandards(language: string, age: string, week: string, day: string, activity: string, parse: boolean = true) {
    const prefix = this.generatePrefix(language, age);
    const sectionDay = !!day ? `-D${day}` : '';
    const content = `${prefix}-DS-W${week}${sectionDay}-${activity.toUpperCase()}-00001`;
    return this.getContent(content, parse);
  }

  /**
   * Save daily standards
   * @param language resource language
   * @param age age group
   * @param week week number
   * @param day day number
   * @param activity activity identifier
   * @param files resource files
   */
  public async saveDailyStandards(language: string, age: string, week: string, day: string, activity: string, files: ContentResource[]) {
    const prefix = this.generatePrefix(language, age);
    const sectionDay = !!day ? `-D${day}` : '';
    const content = `${prefix}-DS-W${week}${sectionDay}-${activity.toUpperCase()}-00001`;
    const title = this.generateTitle(language, age, 'ds', week, day, activity);
    return this.updateContent(content, title, files);
  }

  /**
   * Get home connections.
   * If not found returns code 403
   * @param language resource language
   * @param age age group
   * @param week week number
   * @param day day number
   * @param parse parse template
   */
  public async getHomeConnections(language: string, age: string, week: string, day: string, parse: boolean = true) {
    const prefix = this.generatePrefix(language, age);
    const sectionDay = !!day ? `-D${day}` : '';
    const content = `${prefix}-HC-W${week}${sectionDay}-00001`;
    return this.getContent(content, parse);
  }

  /**
   * Save home connections
   * @param language resource language
   * @param age age group
   * @param week week number
   * @param day day number
   * @param files resource files
   */
  public async saveHomeConnections(language: string, age: string, week: string, day: string, files: ContentResource[]) {
    const prefix = this.generatePrefix(language, age);
    const sectionDay = !!day ? `-D${day}` : '';
    const content = `${prefix}-HC-W${week}${sectionDay}-00001`;
    const title = this.generateTitle(language, age, 'hc', week, day);
    return this.updateContent(content, title, files);
  }

  /**
   * Get reflection content
   * @param language resource language
   * @param area activity id
   */
  public async getReflections(language: string, area: string) {
    const prefix = this.generatePrefixForReflections(language, area);
    const content = `${prefix}-TR-00001`;
    return this.getQuestionsXML(content);
  }

  /**
   * Get assessment content
   * @param content content folder id
   */
  public async getAssessmentQuestions(content: string) {
    return this.getQuestionsXML(content);
  }

  /**
   * Get asseessment templates
   * @param language resource language
   * @param age assessement age group
   */
  public getAssessmentTemplate(language: string, age: string) {
    const prefix = this.generatePrefix(language, age);
    const id = `${prefix}-AS-00000`;
    return `${this.contentRoot}/${id}/${id}_Text.pdf`;
  }

  /**
   * Generate signed URL's for content upload
   * @param content content folder id
   * @param files list of files to sign
   */
  private getSignedUrls(content: string, files: ContentResource[]): Promise<SignedURL[]> {
    // prepare list of files with their content type
    const body = files.map((file) => ({
      filename: file.filename,
      type: file.body.type,
    }));

    // check if all files have specified filename and type
    // if not throw error
    const missingParams = body.some((file) => file.filename === null || file.type === null);
    if (missingParams) {
      throw 'ERROR. Missing file parameters';
    }

    // create session header
    const session = this.appState.restore('session');
    const headers = new HttpHeaders({ 'x-vms-session': session });

    // call API to generate signed URL's
    const signUrl = `${this.apiRoot}/content/${content}/sign`;
    return this.http
      .post<SignedURL[]>(signUrl, body, { headers })
      .toPromise();
  }

  /**
   * Update info.xml file for content folders after resources change
   * @param content content folder id
   * @param title content title
   */
  private updateInfoXml(content: string, title: string, files: ContentResource[]): Promise<any> {
    // create session header
    const session = this.appState.restore('session');
    const headers = new HttpHeaders({ 'x-vms-session': session });

    const body = {
      title,
      files: files.map((file) => file.filename),
    };

    // call API to regenerate info.xml file
    // for specific content folder
    const infoUrl = `${this.apiRoot}/content/${content}/info`;
    return this.http.post(infoUrl, body, { headers }).toPromise();
  }

  /**
   * Update content folder
   * @param content content folder id
   * @param title content title
   * @param files list of files to include
   */
  private async updateContent(content: string, title: string, files: ContentResource[]) {
    // generate signed URL's
    const signed = await this.getSignedUrls(content, files);

    // upload files
    const uploads = files.map((file) => {
      // match signed URL to each of the files
      // set correct content type
      // upload file to S3
      const url = signed.find((sign) => sign.filename === file.filename).url;
      const headers = new HttpHeaders({ 'Content-Type': file.body.type });
      // tslint:disable-next-line: object-literal-shorthand
      return this.http.put(url, file.body, { headers: headers }).toPromise();
    });

    // wait till all files are uploaded
    // update info.xml to finalize content folder
    return Promise.all(uploads).then(() => this.updateInfoXml(content, title, files));
  }

  /**
   * Generate content prefix
   * @param language resource language
   * @param age age group 
   */
  private generatePrefix(language: string, age: string) {
    if (language === 'en') {
      return `LBP-${age.toUpperCase()}`;
    } else {
      return `LBP-${language.toUpperCase()}-${age.toUpperCase()}`
    }
  }

  
  /**
   * Generate content prefix
   * @param language resource language
   * @param area area(activity)
   */
  private generatePrefixForReflections(language: string, area: string) {
    if (language === 'en') {
      return `LBP-${area.toUpperCase()}`;
    } else {
      return `LBP-${language.toUpperCase()}-${area.toUpperCase()}`
    }
  }

  /**
   * Generate pretty title for content
   * @param language resource language
   * @param age age group
   * @param mode mode
   * @param week week number
   * @param day day number
   * @param activity activity identifier
   */
  private generateTitle(language: string, age: string, mode: string, week: string = null, day: string = null, activity: string = null): string {
    age = this.teacherService.getAges().find((a) => a.key === age).title;
    mode = mode === 'ds' ? 'Daily Standards' : this.teacherService.getModes().find((m) => m.key === mode).title;
    language = language = 'en' ? 'English' : 'Spanish';
    let title = `LBP ${language} ${age} ${mode}`;

    if (!!week) {
      title += ` Week ${week}`;
    }

    if (!!day) {
      title += ` Day ${day}`;
    }

    if (!!activity) {
      activity = this.teacherService.getActivities().find((a) => a.key === activity).title;
      title += ` ${activity}`;
    }

    return title;
  }

  /**
   * Read questions XML
   * @param content content identifier
   */
  private async getQuestionsXML(content: string): Promise<QuestionsFolder> {

  const questions_url = `${this.contentRoot}/${content}/${content}_Questions.xml`;

  // 1. load content
  // 2. read file contents as text
  // 3. capture Amazon version header
  const questions = await this.http
    .get(questions_url, { responseType: 'text', observe: 'response', withCredentials: true })
    .pipe(
      map((response) => ({
        body: response.body,
        version: response.headers.get('x-amz-version-id'),
      }))
    )
    .toPromise();

  // parse questions xml content
  const questions_xml = xml2js.xml2js(questions.body);
  const body = questions_xml.elements[0].elements.map((question) => ({
    id: question.attributes.id,
    text: question.attributes.text,
    type: question.attributes.type,
    answers: !!question.elements
      ? question.elements.map((answer) => ({
          id: answer.attributes.id,
          text: answer.attributes.text,
        }))
      : null,
  }));


  // get titles for question sections from info.xml
  const info_url = `${this.contentRoot}/${content}/info.xml`;

  const info = await this.http
    .get(info_url, { responseType: 'text', observe: 'response', withCredentials: true })
    .pipe(
      map((response) => ({
        body: response.body,
        version: response.headers.get('x-amz-version-id'),
      }))
    )
    .toPromise();
  // parse info xml content
  const info_xml = xml2js.xml2js(info.body);

  const section_title = info_xml.elements?.[0]?.elements?.[2]?.elements?.[0]?.elements?.[0]?.text || " ";
  
  return {
    body,
    version: questions.version,
    title: section_title,
  };
}
}

export interface ContentResource {
  filename: string;
  body: Blob;
}

export class ContentFolder {
  content?: string;
  files: string[];
  template?: string;
}

export interface QuestionsFolder {
  body: Question[];
  version: string;
  title: string;
}

export interface Question {
  id: string;
  text: string;
  type: string;
  answers: Answer[];
}

export interface Answer {
  id: string;
  text: string;
}

export interface SignedURL {
  filename: string;
  url: string;
}
