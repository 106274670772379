

<div class="wrapper">    
    <div class="center_block">
        <div class="form_block">
            <div  class="cloud_img"> 
                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.39 157.51">
                    <defs>
                      <style>
                        .cls-1 {
                          fill: #fff;
                        }
                      </style>
                    </defs>
                    <path class="cls-1" d="M251.94,94.18c0-17.73-12.51-32.42-28.83-35,0,0,0-.05,0-.08,0-43.82-48.07-67.75-80.78-42C123.39-.53,93.61,5.32,82,28.86,69.35,15.33,47.17,22.29,43.88,41c-18.46.34-33.33,16-33.33,35.37,0,17.73,12.51,32.42,28.83,35v.08c0,43.82,48.07,67.75,80.78,42,19,17.61,48.73,11.75,60.3-11.78,12.69,13.53,34.87,6.57,38.16-12.12C237.08,129.21,251.94,113.51,251.94,94.18Z" transform="translate(-10.55 -6.51)"/>
                  </svg>                                  
            </div>
            <div class="container">
                <div class="welcome_text">
                    <p>{{ 'login.lb_curriculum' | translate }}</p>  
                    <p>{{ 'login.few_clicks' | translate }}</p>  
                </div>
        
                <form class="form">
                    <div class="login">
                        <div class="label">{{ 'login.login' | translate }}:</div>
                        <input  class="_input"
                                name="login"
                                id="login" 
                                type="text" 
                                [(ngModel)]="login"
                                (keydown.enter)="onSubmit()"
                                (keydown)="inputChange()">
                    </div>
                
                    <div class="password">
                        <div class="label">{{ 'login.password' | translate }}:</div>
                        <input  class="_input" 
                                name="password"  
                                id="password" 
                                type="password"  
                                [(ngModel)]="password"
                                (keydown.enter)="onSubmit()"
                                (keydown)="inputChange()">
                    </div>     
                    
                    <div  class="_row">
                      <div  class="submit_btn" [ngClass]="{'disableBtn': !submit_btn }" (click)="onSubmit()">{{ 'login.enter' | translate }}</div> 

                      <!-- Loader spinner-->
                        <div class="myloader" *ngIf="!submit_btn">
                          <loader [page]="true" ></loader>
                        </div>
                      <!--end spinner -->
                    </div>
                    
                </form>  
        
                <div class="help_text">
                      <a href="/forgot-pass">{{ 'login.forgot' | translate }}</a> 
                </div>

                <div class="error_text" *ngIf="passwordError || loginError || accessError || projectError || incorrectDataError === 'error'">
                    <p> {{ 'login.incorrect_1row'  | translate}} <br> {{ 'login.incorrect_2row'  | translate}} </p>
                </div>

                <div class="error_text" *ngIf="incorrectDataError && incorrectDataError !== 'error'">
                  <p *ngIf="incorrectDataError === 'Please contact your Educational Advisor to discuss your account.'; else elseBlock">
                    {{ 'login.incorrectData_1row'  | translate}} <br> {{ 'login.incorrectData_2row'  | translate}}
                  </p>
                  <ng-template #elseBlock>{{incorrectDataError | translate}}</ng-template>
               </div>
            </div>
        </div> 
         
        <div *ngIf="language == 'en'" class="welcom_img"> </div>
        <div *ngIf="language == 'es'" class="welcom_img_es"> </div>
    </div>
 <div></div>
</div>  

<div class="isbn">
 
  <p>ISBN #’s</p>
  <p>Infant (979-8-9919275-4-3) Younger Toddler (979-8-9919275-3-6) Older Toddler (979-8-9919275-2-9) Preschool 3 (979-8-9919275-1-2) Pre-K 4 (979-8-9919275-0-5)</p>
</div>