import { Component, OnInit  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import 'hammerjs'
import { PreviousRouteService } from 'src/app/services';

import { AppState, SessionService, SwipeService } from 'src/app/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [PreviousRouteService],
  styleUrls: ['./app.component.scss'],
})
export class AppComponent  implements OnInit  {


  constructor(private swipeService: SwipeService,
              private sessionService: SessionService,
              private appState: AppState,
              translateService: TranslateService) {
    
    // user is not logged in yet so no user profile information
    // restore last used language from local storage
    const language = this.appState.restore('language') || 'en';
    translateService.use(language);
  }

  public onSwipeLeft() {
    this.swipeService.swipeLeft();
  }

  public onSwipeRight() {
    this.swipeService.swipeRight();
  }

  public ngOnInit(): void {
    const session = this.appState.restore('session');
    if (session) {
      this.sessionService.restoreSessionTimeout();
      this.sessionService.restoreCheckSession();
    }
  }
  
}
