import { Component, OnInit } from '@angular/core';
import { IClass } from 'src/app/interfaces/IClass';
import { ISchool } from 'src/app/interfaces/ISchool';
import { ITeacher } from 'src/app/interfaces/ITeacher';
import { AdminService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/services/app.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({

  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})

export class CommentsComponent implements OnInit {

  public unknownPage: string = 'admin_tr.teacher_comments';

  public week: number | null = null;
  public day: number | null = null;  
  public school: ISchool; 

  public content: any;
  public answers: any[];
  public loading: boolean = false;
  public disabled: boolean = false;

  // filters
  public listAgeGroups: {key: string, title: string}[];
  public listClasses: IClass[];
  public listTeachers: ITeacher[];
  public weeks: Array<number> = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52]
  public days: Array<number> = [0,1,2,3,4,5]
  
  public currentTeacher: ITeacher = null;
  public currentAgeGroup: {key: string, title: string} = null;
  public currentClass: IClass = null;

  constructor( private appState: AppState, 
               private cdr: ChangeDetectorRef,
               private translateService: TranslateService,
               private adminService: AdminService)   { } 

  ngOnInit(): void {
  
    this.listAgeGroups = this.adminService.getAgeGroup();
    this.school = this.appState.restore('school');

    setTimeout(() => {
      this.restoreFilters()
    }, 700)
  }

  public restoreFilters(){

    const _currentAgeGroup = this.appState.restore('currentAgeGroup') || null;

    if(_currentAgeGroup) {

      this.currentAgeGroup = this.listAgeGroups.find( ageGroup => ageGroup.key ===  _currentAgeGroup.key) || null; 

      if(this.currentAgeGroup) {
      
        this.getTeachers(this.school.idnumber, this.currentAgeGroup.key, true)
      }
    }
    
    this.currentClass = this.appState.restore('currentClass') || null;
    this.day = this.appState.restore('day') || this.days[0];
    this.week = this.appState.restore('week') || this.weeks[0];
    this.loading = false;
    this.cdr.detectChanges();
  }


  public getAgeGroup(age_group: string) {

    let age = this.adminService.getAgeGroup().find((x) => x.key === age_group);

    return age ? age.title : null;
  }

  public onChangeSchool(event: ISchool) {
    if (event) {
      this.school = event;
  
      this.currentAgeGroup = null;
      this.currentTeacher = null;
      this.currentClass = null;
      this.day =  this.days[0];
      this.week =  this.weeks[0];

      this.content = null;

      if (this.school && this.currentAgeGroup) {
        if (this.currentAgeGroup.key) {
          this.getTeachers(this.school.idnumber, this.currentAgeGroup.key, false);
        }
      } 
    }
  }

  private getTeachers(schoolId, ageId, type: boolean): void {
    this.loading = true;
    this.adminService.getTeachersByAgeAndSchool(schoolId, ageId).then((teachers: ITeacher[]) => {

      this.listTeachers = Object.assign([], teachers.sort((a, b) => (a.firstname + a.lastname < b.firstname + b.lastname ? -1 : 1)));    
      this.listTeachers.unshift({birthdate: null, email: null, firstname: this.translateService.instant('admin_shared.all_teachers'), lastname: null, username: null}); 
     
      if(type){
        const _currentTeacher = this.appState.restore('currentTeacher') || null;
        this.currentTeacher = _currentTeacher ? this.listTeachers.find( teacher => teacher.username ===  _currentTeacher.username) : this.listTeachers[0];
      } else{
        this.currentTeacher = this.listTeachers[0];     
      }
      
      this.getClasses()

      }).catch((error) => {
        console.log('Error get teacher: ', error);
      });
  }

  private getClasses(){ 
    this.loading = true;
    this.adminService.getActiveClassesByAgeSchoolAndTeacher(this.school.idnumber, this.currentAgeGroup.key, this.currentTeacher.username).then((classes: IClass[]) => {
      
      console.log(classes)
      this.listClasses = Object.assign([], classes.sort((a, b) => (a.name < b.name ? -1 : 1)));
      
      this.listClasses.unshift({
        date_begin: null,
        date_end: null,
        idnumber: null,
        main_tchr_first: null,
        main_tchr_last: null,
        main_tchr_username: null,
        name: this.translateService.instant('admin_shared.all_classes'),
        age_group: null
      });

      const _currentClass = this.appState.restore('currentClass') || null;

      if(_currentClass){
        this.currentClass =  this.listClasses.find( _class => _class.idnumber ===  _currentClass.idnumber) 
      } else{  
        if(this.listClasses.length == 2){
          this.currentClass = this.listClasses[1];
        } else{  
          this.currentClass = this.listClasses[0];
        }
      }
      
      this.loading = false;
    })
  }

  public onChange(type: string){

    if(type == 'ageGroup'){

      this.currentClass = null;
      this.content = null;
      this.currentTeacher = null;
      this.appState.store('currentAgeGroup',this.currentAgeGroup,true);
      this.appState.remove('currentTeacher');
      this.appState.remove('currentClass');
      this.getTeachers(this.school.idnumber, this.currentAgeGroup.key, false)
    }
    if(type == 'teacher') {
      
      this.currentClass = null;
      this.content = null;
      this.appState.store('currentTeacher', this.currentTeacher,true);
      this.appState.remove('currentClass');
      this.getClasses()
    }
    if(type == 'class') {
      
      this.content = null;
      this.appState.store('currentClass', this.currentClass, true);
    }
    if(type == 'week') {

      this.content = null;
      this.appState.store('week',this.week, true)
      if(this.week == 0){
        this.day = 0;      
        this.appState.remove('day');
      }
    }
    if(type == 'day') {
      this.appState.store('day', this.day, true);
      this.content = null;
    }
  }

  public load() {

    this.loading = true; 
    this.disabled = true;
    const week = this.week == 0 ?  null : this.week;
    const day = this.day == 0 ?  null : this.day;
    this.adminService.getCommentsBySchoolAgeTeacherClassWeekDay(this.school.idnumber, this.currentAgeGroup.key ,this.currentTeacher.username , this.currentClass.idnumber, week,day)
    .then((comments) => {
        this.content = comments;
        this.loading = false;
        this.disabled = false;
    })
    .catch((error) => {
        this.disabled = false;
        console.error('Failed to load comments:', error);
    });
  }

  public goTo(type:string){
    if(this.day != 0){
      if (type == '+'){
        if (this.day == 5){
          this.day = 1;
          if (this.week == 52){
            this.week = 1;
          } else{
              this.week++;
          }
        } else{
          this.day++;
        }
      }
      if (type == '-'){
        if (this.day == 1){
          this.day = 5;
          if (this.week == 1){
            this.week = 52;
          } else{
              this.week--;
          }
        } else{
          this.day--;
        }
      }  
    } else{
      if(type == '+'){
        if(this.week == 52) {
          this.week = 1;
        } else{
          this.week++;
        }
      }
      if(type == '-'){
        if(this.week == 1) {
          this.week = 52;
        } else{
          this.week--;
        }
      }
    }
    
    
    this.appState.store('day',this.day, true);
    this.appState.store('week',this.week, true);
    this.load()
  }

}
