<app-menu [home]="false" [school]="school"></app-menu>

<section class="editor-comp">
  <!--filters panel start-->
  <app-filters-panel
    [version]="version"
    [templateChanged]="templateChanged"
    (onTemplateClear)="vmOnTemplateClear($event)"
    (onLoad)="vmOnLoad($event)"
    (onSave)="vmOnSave($event)"
  ></app-filters-panel>
  <!--filters panel end-->

  <!--tabs start-->
  <section class="tabs-container">
    <!--container start-->
    <div class="container-fluid">
      <!--row start-->
      <div class="row">
        <!--col start-->
        <div class="col">
          <!--tabs link start-->
          <ul class="nav nav-tabs">
            <li class="nav-item" *ngFor="let tab of tabsList">
              <a class="nav-link" [ngClass]="{ active: tab === activeTab }" (click)="vmTabsSwitch(tab)">{{ tab }}</a>
            </li>
          </ul>
          <!--tabs link end-->
        </div>
        <!--col end-->
      </div>
      <!--row end-->
    </div>
    <!--container end-->
  </section>
  <!--tabs end-->

  <!--main content wrapper start-->
  <main class="main-content-wrapper">
    <!--container start-->
    <div class="container-fluid" *ngIf="activeTab === tabsList[0]">
      <!--row start-->
      <div class="row">
        <!--column start-->
        <div class="col">
          <!--xml area block start-->
          <section class="xml-area-block">
            <textarea name="xml-input" id="xml-input" class="border rounded" [(ngModel)]="inputValue" (keyup)="vmOnInputChange($event)"></textarea>
          </section>
          <!--xml area block end-->
        </div>
        <!--column end-->
        <!--column start-->
        <div class="col">
          <!--preview page block start-->
          <section class="preview-page-block" *ngIf="loadView" [ngSwitch]="parameters.mode">
            <!--view start-->
            <app-daily-activity
              *ngSwitchCase="'da'"
              [xml]="parsedXml"
              [resources]="resources"
              [activity]="parameters.activity"
              [age]="parameters.age"
              [standards]="'no_standards'"
            ></app-daily-activity>
            <app-daily-activity
              *ngSwitchCase="'wda'"
              [xml]="parsedXml"
              [resources]="resources"
              [activity]="parameters.activity"
              [age]="parameters.age"
              [standards]="'no_standards'"
            ></app-daily-activity>
            <!--view end-->

            <!--view start-->
            <app-lesson-plans
              *ngSwitchCase="'lp'"
              [xml]="parsedXml"
              [age]="parameters.age"
              [week]="parameters.week"
              [mode]="parameters.mode"
            ></app-lesson-plans>
            <app-lesson-plans
              *ngSwitchCase="'wlp'"
              [xml]="parsedXml"
              [age]="parameters.age"
              [week]="parameters.week"
              [mode]="parameters.mode"
            ></app-lesson-plans>
            <!--view end-->

            <!--view start-->
            <app-daily-standarts *ngSwitchCase="'ds'" [xml]="parsedXml"></app-daily-standarts>
            <app-daily-standarts *ngSwitchCase="'wds'" [xml]="parsedXml"></app-daily-standarts>
            <!--view end-->

            <!--view start-->
            <app-materials-view *ngSwitchCase="'mtweek'" [xml]="parsedXml" [week]="parameters.week"></app-materials-view>
            <!--view end-->

            <!--view start-->
            <app-home-connection [resources]="resources" *ngSwitchCase="'hc'"></app-home-connection>
            <app-home-connection [resources]="resources" *ngSwitchCase="'whc'"></app-home-connection>
            <!--view end-->
          </section>
          <!--preview page block end-->
        </div>
        <!--column end-->
      </div>
      <!--row end-->
    </div>
    <!--container end-->

    <!--resources component start-->
    <app-resources *ngIf="activeTab === tabsList[1]" [clearResources]="clearResources" (resourcesChanged)="vnOmResourcesChange($event)"></app-resources>
    <!--resources component end-->
  </main>
  <!--main content wrapper end-->
</section>

<!--progress bar start-->
<div class="editor-progress-bar" *ngIf="progressBar">
  <div class="wrapper">
    <ngb-progressbar type="info" [value]="100" [striped]="true" [animated]="true">{{ progressBarLabel }}</ngb-progressbar>
  </div>
</div>
<!--progress bar end-->

<!--toasts start-->
<app-toasts></app-toasts>
<!--toasts end-->


<div class="loader" *ngIf="loader">
  <loader></loader>
</div>