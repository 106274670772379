import {Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ISchool } from 'src/app/interfaces/ISchool';
import { AdminService, AppState } from 'src/app/services';
import { IAssessmentAge } from 'src/app/interfaces/IAssessmentAge';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-students-assessment',
    templateUrl: './students.assessment.component.html',
    styleUrls: ['./students.assessment.component.scss'],
  })

export class AdminAssesmentStudentsComponent implements OnInit {

    public unknownPage: string = this.translateService.instant('assessment.title');

    public schools: ISchool;
    public assessment;
    public currentSchool: ISchool;
    public children;
    public showActualChildren: boolean = true;
    public searchText: string ='';

    public loading: boolean = false;

    constructor(private adminService: AdminService,
        private activatedRoute: ActivatedRoute,
        private appState: AppState,
        private _location: Location,
        private router: Router,
        private translateService: TranslateService) {
       
    }

    public ngOnInit(): void {

        this.currentSchool = this.appState.restore('school');
        this.assessment = this.activatedRoute.snapshot.params['assessment'];
        this.unknownPage =  'assessment.title' + ':' + this.adminService.getAssessment().find((x) => x.key === this.assessment).titleKey;
        this.load();
    }

    public onChangeSchool(school: ISchool) {

        this.currentSchool = school;

        this.load();
    }
    private getAssessment(assessments, username) {

        return assessments.filter((x) => x.student === username)
                          .sort((a, b) => (a.date > b.date ? -1 : 1));

    }
    private getName(assessments: IAssessmentAge[], username: string) {

        let item = assessments.find((x) => x.student === username);
        return {
            lastname: item.student_last,
            firstname: item.student_first,
            active: item.student_active
        }
    }

    private load() {

        this.adminService.getChildrenByAssessment(this.currentSchool.idnumber, this.assessment).then((assessments) => {
            
            const unique = [...new Set(assessments.map(item => item.student))];

            this.children = unique.map((child) => {

                return {
                    firstname: this.getName(assessments, child).firstname,
                    lastname: this.getName(assessments, child).lastname,
                    username: child,
                    active: this.getName(assessments, child).active,
                    assessments: this.getAssessment(assessments, child)
                }
            }).sort((a, b) => (a.firstname + a.lastname < b.firstname + b.lastname ? -1 : 1));
            this.loading = true;

        })
    }

    public onBack() {
        this._location.back();
    }

    public getToLocaleDate(date) {

        let _date = new Date(date * 1000);

        return _date.toLocaleDateString();
    }
    
    public onDelete(assessment) {

        if (confirm(this.translateService.instant('admin_import.are_you_sure'))){ 

            this.adminService.deleteAssessment(assessment.id, assessment.student).then((response) => {

                let _child = this.children.find((child) => child.username === assessment.student);

                _child.assessments = _child.assessments.filter((x) => x.id !== assessment.id);


                if (_child.assessments.length === 0) {

                    this.children = this.children.filter((x) => x.username !== assessment.student);
                }
                this.adminService.resetChildrenByAssessmentCache();
            })
        }
    }
    public onEdit(assessment) {
        this.router.navigate([assessment.id, assessment.student], { relativeTo: this.activatedRoute });
    }
}