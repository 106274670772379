import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { TeacherService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-materials-view',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss','../../teacher/teacher.component.scss'],
})
export class MaterialsViewComponent implements OnInit, OnChanges {

  public currentYear;
  public language: string;
  
  /**
   * Table data to show.
   */
  public tableData: ITableData = {
    activities: [
      { id: 'st', sortBy: 1, title: null, items: [] },
      { id: 'll', sortBy: 2, title: null, items: [] },
      { id: 'pd', sortBy: 3, title: null, items: [] },
      { id: 'mm', sortBy: 4, title: null, items: [] },
      { id: 'ir', sortBy: 5, title: null, items: [] },
      { id: 'cg', sortBy: 6, title: null, items: [] }
    ],
  };

  /**
   * Flag to show table bodyt or not.
   */
  public showTable: boolean = false;

  /**
   * XML Data to get data for table.
   */
  private xmlData: any = null;

  /**
   * Pretty titles for activities.
   */
  private activitiesTitles: Array<{ key: string; title: string }> = null;

  /**
   * Input `age parameter` from filters.
   */
  @Input()
  public age: string = null;

  /**
   * Input `week/day parameter` from filters.
   */
  @Input()
  public week: string = null;
  @Input() public day: string = null;

  /**
   * Input xml data.
   */
  @Input()
  public set xml(data: any) {
    this.xmlData = data;
  }

  @Output()
  public selected: EventEmitter<{ activity: string; week: number }> = new EventEmitter();

  constructor(private teacherService: TeacherService,
              private translateService: TranslateService) {

    this.activitiesTitles = this.teacherService.getActivities();
  }

  public ngOnInit(): void {
    this.tableData.activities.forEach((_activity) => {
      _activity.title = this.activitiesTitles.find((_a) => _a.key === _activity.id).title;
    });

    this.currentYear = new Date().getFullYear();
    this.language = this.translateService.currentLang;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!!this.xmlData) {
      this.vmCreateTableData(this.xmlData);
    } else {
      this.vmResetTableData();
    }
  }

  public vmOnSelected(code: string): void {
    this.selected.emit({ activity: code, week: +this.week });
  }
  
  public getCopyrite(){

    let _copyright = this.translateService.instant('shared.copyright');
    let result =  _copyright.replace('2023', this.currentYear);
    return  result;
  }

  public exit() {

    history.back()
  }

  public getAgeTitle(ageKey: string): string {

    const age = this.teacherService.getAges().find((a) => a.key == ageKey);
    return age.title;
  }

  private vmCreateTableData(data: any): void {
    this.vmResetTableData();

    this.tableData.activities.forEach((_tableActivity: ITableActivity) => {
      const xmlActivity: { type: string; name: string; elements: any[]; attributes: { id: string } } = data.elements.find(
        (_activity: { type: string; name: string; elements: any[]; attributes: { id: string } }) => _activity.attributes.id === _tableActivity.id
      );

      const items = {
        weekId: this.week,
        items: [],
      };

      if (!!xmlActivity && !!xmlActivity.elements) {
        items.items = xmlActivity.elements.map((_item: { type: string; name: string; elements: any[] }) => {
          if (_item.elements) {
            return   _item.elements[0].text;
          }        
        });
        items.items = items.items.filter((x) => x);
      }

      _tableActivity.items = [..._tableActivity.items, items];
    });

    // Filter activities that dont have any data to show.
    this.tableData.activities = this.tableData.activities.filter((_activity: ITableActivity) => {
      return !_activity.items.every((_item) => _item.items.length === 0);
    });

    this.tableData.activities = this.vmSortdata(this.tableData.activities);

    this.showTable = true;
  }

  private vmSortdata(data: Array<ITableActivity>): Array<ITableActivity> {
    return data.sort((a, b) => {
      return a.sortBy > b.sortBy ? 1 : -1;
    });
  }

  /**
   * Method to reset table data.
   */
  private vmResetTableData(): void {
    this.tableData = {
      activities: [
        { id: 'st', sortBy: 1, title: null, items: [] },
        { id: 'll', sortBy: 2, title: null, items: [] },
        { id: 'pd', sortBy: 3, title: null, items: [] },
        { id: 'mm', sortBy: 4, title: null, items: [] },
        { id: 'ir', sortBy: 5, title: null, items: [] },
        { id: 'cg', sortBy: 6, title: null, items: [] }
      ],
    };

    this.vmGetRowTitles();
  }

  /**
   * method to get pretty titles for table rows.
   */
  private vmGetRowTitles(): void {
    this.tableData.activities.forEach((_activity) => {
      _activity.title = this.activitiesTitles.find((_a) => _a.key === _activity.id).title;
    });
  }
}

interface ITableData {
  activities: Array<ITableActivity>; // row
}

interface ITableActivity {
  id: string;
  sortBy: number;
  title: string;
  items: Array<{ weekId: string; items: string[] }>;
}
