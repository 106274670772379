import {Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import * as XLSX from 'xlsx';
import { MessagesService } from 'src/app/services/messages.service';
import { FormGroup } from '@angular/forms';
import { AppState } from 'src/app/services/app.service';
import { ActivatedRoute } from '@angular/router';
import { threadId } from 'worker_threads';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-import',
    templateUrl: './import.component.html',
    styleUrls: ['./import.component.scss'],
  })

export class ImportComponent implements OnInit {

    public loading: boolean = true;
    // unknownPage is value what to show us where we are
    // we dont go to link in menu
    // this is to show users where we are
    public unknownPage: string = 'admin_shared.import';

    public fileToUpload: File = null;
    public datas: IImportUser[];
    public users: any;
    public unActiveBtn: boolean = true;

    public fileName: string;
    public school: any;

    @ViewChild('myInput') myInputVariable: any;

    constructor(public getMsgError: MessagesService,
                public adminService: AdminService,
                public appState: AppState,
                public translateService: TranslateService,
                public activatedRoute: ActivatedRoute) {
    }

    public ngOnInit(): void { 
    
      this.school = this.appState.restore('school');
    }

    public async handleFileInput(files: FileList) {

        this.fileToUpload = files.item(0);

        let res = await files.item(0).arrayBuffer();

        let bstr = new Uint8Array(res).reduce((string, el) => string + String.fromCharCode(el), "");

        let workbook = XLSX.read(bstr, {type: 'binary', cellDates: true, dateNF: 'mm/dd/yyyy;@'});
        let first_sheet_name = workbook.SheetNames[0];

        let worksheet = workbook.Sheets[first_sheet_name];
        let datas: IImportUser[] = XLSX.utils.sheet_to_json(worksheet);

        this.users = datas;
        this.buildTable(datas);
    }

    protected getFileName() {
      return this.translateService.instant('messages.teacher_import');
    }

    reset() {
      this.myInputVariable.nativeElement.value = "";
    }

    protected buildTableParams(datas) : boolean 
    {
      let IsError: boolean = false;

      datas.forEach((x) => {

        x.errors = {
          username: null,
          password: null,
        }

        // check fields is not empty
        x.errors.password = this.onCheckEmptyField(x.password, 'login.password');
        x.errors.username = this.onCheckEmptyField(x.username, 'profile.username');
        x.errors.firstname = this.onCheckEmptyField(x.firstname, 'profile.firstname');
        x.errors.lastname = this.onCheckEmptyField(x.lastname, 'profile.lastname');

        // check fields have errors
        if (!x.errors.password) {
          x.errors.password = this.getMsgError.getMsgPassword(x.password.toString());
        }
        if (!x.errors.username) {
          x.errors.username = this.getMsgError.getMsgUsername(x.username.toString());
        }
        
        if (x.errors.password || x.errors.username || x.errors.firstname || x.errors.lastname) {
            IsError = true;
            this.unActiveBtn = true;
            this.loading = true;
        }
      });

      return IsError;
    }

    protected onCheckEmptyField(value, field) {

      if (value && value.toString().length > 0) {

        return null;
      } else {
        return this.translateService.instant(field) + this.translateService.instant("messages.is_empty");
      }
    }

    protected onCheckUniqueUser(datas){

      this.loading = false;

      let _isInArray: boolean = false;

      let valueArr = datas.map(function(item){ return item.username });

      valueArr.forEach(function(item, idx){ 

        let isInArray = valueArr.indexOf(item) != idx;

        if (isInArray) {

          let _array = datas.filter((x) => x.username === item);

          _array.forEach((y) => {
            y.errors.username = "messages.username_duplicated";
          });
          _isInArray = true;
        }
      });

      this.loading = true;

      return _isInArray;
    }

    protected buildTable(datas){

      this.loading = false;

      let IsError: boolean = false;

      IsError = this.buildTableParams(datas);

      let isUnique = this.onCheckUniqueUser(datas);

      // check or username exist only 
      // if no errors anymore
      if (!IsError && !isUnique) {

        this.loading = false;
        
        this.getMsgError.ExistsArray(datas.map((x) => x.username)).then((result) => {
        
          this.unActiveBtn = result.some((x) => x.exists) ? true : false;

          result.forEach((x) => {
            if (x.exists) {

              let user = datas.find((y) => y.username === x.username);

              user.errors.username = "messages.username_exists";

            }            
          })
          this.loading = true;
        }).catch((error) => {
            if (error.error && error.error.code === "NOT_UNIQUE_USERS") {

              error.error.data.forEach((item) => {

                let _array = datas.filter((x) => x.username === item);

                _array.forEach((y) => {
                  y.errors.username = "messages.username_duplicated";
                });
              })

              this.loading = true;
            }
            console.log(error);
        })       
      }
      
      this.datas = datas; 
         
    }

    public downloadTemplate() {

      let list = this.listParameters();
      // export json to Worksheet of Excel
      let listWS = XLSX.utils.json_to_sheet(list);

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new();

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, listWS);

      // export Excel file
      XLSX.writeFile(wb, this.getFileName());

    }
    
    protected listParameters() {

      return  [{
        username: null,
        password: null,
        firstname: null,
        lastname: null
      }];
    }

    public addUsers() {

      this.loading = false;

      this.users = this.datas.map((x) => {
        return {
          username: x.username,
          password: x.password,
          firstname: x.firstname,
          lastname: x.lastname
        }
      })
      let school = this.appState.restore('school').idnumber || this.activatedRoute.snapshot.params['idschool'];

      this.adminService.addTeachersImport(school, this.users).then((result) => {
        
        result.forEach((x) => {
          let user = this.datas.find((y) => x.username === y.username);
          if (x.token) {            
            user.status = 'saved';
          } else {
            user.status = 'error';
          }
        });

        this.adminService.resetTeachersCache();

        this.loading = true;
        this.unActiveBtn = true;
      })
    }
    public onChangeSchool(school) {
        this.school = school;
    }
}

interface IImportUser {
  username: string,
  password: string,
  firstname: string,
  lastname: string,
  email?: string,
  birthdate?: Date,
  status?: string,
  errors?: {
    username: string,
    password: string
    email: string
  }
}