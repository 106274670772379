<div class="wrapper">
    <div class="mb-3">
        <app-menu [mode]="'teacher_reflections.title'" [details]="''" [school]="school"></app-menu> 
        <app-filters [showWeek]="true"  [showDay]="true"  [showAge]="true"  (dayChanged)="dayChanged($event)" (weekChanged)="weekChanged($event)" (_ageChanged)="_ageChanged($event)"></app-filters>
    </div>

    <div class="container-lg">
        <div *ngIf="!loading">
            <table class="_table" *ngIf="reflections.length > 0; else noClasses" class="table">
                <tr class="header">
                   <td class="area first">
                    <div class="class_title">{{'shared.class' | translate}}</div>
                   </td>
                   <td class="area" *ngFor="let area of areas"  [ngClass]="{left_padding: areas.length < 5}">
                    <div class="svg-wrapper" [ngClass]="{es_icons:language == 'es'}">
                        <div class="svg" [ngClass]="{al: area == 'al',
                                                     cg: area == 'cg',
                                                     ir: area == 'ir',
                                                     ll: area == 'll',
                                                     mm: area == 'mm',
                                                     pd: area == 'pd',
                                                     st: area == 'st' }"> 
                                                 
                         </div>
                       </div>
                   </td>
                </tr>
                <tr class="_item" *ngFor="let item of reflections">
                    <td class="_title">{{item.title}}</td>

                    <td  *ngFor="let reflection of item.reflections_by_area" (click)="select(item.id, reflection.area)" class="_content text-center cursor-pointer">
                        <span class="_icon" *ngIf="reflection.reflections.length != 0">
                            <div class="_svg _checkmark"></div>
                        </span>
                        <span class="_icon" *ngIf="reflection.reflections.length == 0">
                            <div class="_svg _clipboard"></div>
                        </span>
                    </td>
                </tr>
            </table>
        </div>
    
        <ng-template #noClasses>{{'shared.no_class' | translate}}</ng-template>
 
        <div *ngIf="loading" class="loader">
            <loader></loader>
        </div>
    </div>
</div>
