
<!--table start-->
<table class="table">
  <thead>
    <tr>
      <td colspan="2">
        <div class="print-title">
          <div>{{'weekly_overview.title' | translate}}</div>
          <div class="dash" *ngIf="age">–</div>
          <div *ngIf="age">{{ ageTitle | translate}}</div>
          <div class="dash" *ngIf="age && week">–</div>
          <div *ngIf="week">{{'shared.week' | translate}} {{ week }}</div>
        </div>
      </td>
    </tr>
  </thead>

  <tbody>
    <!--table row start-->
    <tr *ngFor="let activity of tableData.activities">
      <!--column with title start-->
      <td class="icon" scope="row">
        <div class="svg-wrapper" [ngClass]="{es_icons:language == 'es'}">
          <div class="svg" [ngClass]="{al: activity.id == 'al',
                                       cg: activity.id == 'cg',
                                       ir: activity.id == 'ir',
                                       ll: activity.id == 'll',
                                       mm: activity.id == 'mm',
                                       pd: activity.id == 'pd',
                                       st: activity.id == 'st' }">
           </div>
         </div>
      </td>
      <!--column with title end-->

      <!--column with day activity start-->
      <td  class="activity" *ngFor="let item of activity.items" (click)="vmOnSelected(activity.id)">
        <!--list start-->
        <div *ngFor="let element of item.items">
          <p class="titleActivity">{{'weekly_overview.activity_name' | translate}}:</p>
          <ul class="plan-list">
            <li *ngFor="let name of element.name" [ngClass]="{withoutPoint: element.name.length == 1}">
              {{  name }}</li>
          </ul>
          <p class="titleActivity">{{'weekly_overview.objective' | translate}}:</p>
          <ul class="plan-list">
            <li *ngFor="let objectives of element.objectives" [ngClass]="{withoutPoint: element.objectives.length == 1}">
              {{  objectives }}</li>
          </ul>
        </div>

        <!--list end-->
      </td>
    </tr>
    <tr class="print-notes">
      <td class="icon" scope="row">
        <div class="svg-wrapper">
          <div class="svg png">
            <img *ngIf="language == 'en'" class="notes" src="../../../../../assets/icons/notes_en.png" alt="lbp-notes-icon">
            <img *ngIf="language == 'es'" class="notes" src="../../../../../assets/icons/notes_es.png" alt="lbp-notes-icon">
           </div>
         </div>
      </td>
      <td class="activity">
      </td>
    </tr>
    <!--table row end-->
  </tbody>
</table>
<!--table end-->


