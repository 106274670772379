<section class="wrapper">
  <div class="container-lg">
    <!--area start-->
    <div class="row _first top" *ngIf="areas">

      <div class="mywraper">
              
        <table class="table TablewithStandarts">
          <tr class="header">
            <th class="title"  *ngFor="let area of areas; let i = index" [ngClass]="{ 'area--green': i === 0, 'area--yellow': i === 1, 'area--blue': i === 2 }">
              {{ area.title | translate }}
            </th>
          </tr>
        
          <tr class="content">
            <td class="text center" *ngFor="let area of areas">
              {{ area.message }}
            </td>
          </tr>

        </table>
        <div class="wrapStandarts">
          <button class="btn btn-link standards" [ngClass]="{es_icon:language == 'es'}" (click)="openStandards()"></button>
        </div>
      </div>     

    </div>
    <!--area end-->

    <!--objectives start-->
    <div class="row" *ngIf="objectives">

      <div class="card col-xl">
        <div class="card-body">
          <div class="card-title area--grey">{{'daily_activities.objective' | translate}}</div>
          <ul class="card-text no-bullets">
            <ng-container *ngFor="let item of objectives">
              <li [ngClass]="{'withoutPoint': objectives.length == 1 }">
                {{ item }}
              </li>
            </ng-container>
          </ul>
        </div>
      </div>

    </div>
    <!--objectives end-->

    <!--standards start-->
    <div class="row" *ngIf="standards &&  standards != 'no_standards'">
      <div class="card col-xl">
        <div class="card-body">
          <div class="card-title area--grey"> {{ stateName | uppercase }}  {{'standards.state_standards' | translate}}</div>
          <ul class="card-text no-bullets"  *ngIf="standards && standards.length > 0">
            <ng-container *ngFor="let item of standards">
              <li class="standard" [ngClass]="{'withoutPoint': objectives.length == 1 }"> {{ item }} </li>
            </ng-container>
          </ul>
          <ul class="card-text no-bullets"  *ngIf="!standards || standards.length == 0" >
             <li [ngClass]="{'withoutPoint': objectives.length == 1 }">{{'standards.no_standards' | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
    <!--standards end-->

    <!--materials start-->
    <div class="row" *ngIf="materials">

      <div class="card col-xl">
        <div class="card-body">
          <div class="card-title area--grey">{{'daily_activities.materials_setup' | translate}}</div>
          <ul class="card-text withLeftMargin">
            <ng-container *ngFor="let item of materials">
              <li [ngClass]="{'withoutPoint': materials.length == 1 }">
                {{ item }}
              </li>
            </ng-container>
          </ul>
        </div>
      </div>

    </div>
    <!--materials end-->

    <!--resources start-->
    <div class="row templateResources" *ngIf="resources">
       
      <!--resource start-->
      <ng-container *ngFor="let resource of resources" [ngSwitch]="resource.type">
        <div class="_container">
          <div (click)="openBunner($event, resource.data, 'video')" class="video" *ngSwitchCase="'video/mp4'">
            <video playsinline src="{{ resource.data }}" >
            </video>
            <div class="triangle"></div>
          </div>
        
          <img (click)="openBunner($event,resource.data,'img')" src="{{ resource.data }}"  controls *ngSwitchCase="'image/jpeg'" />

          <img src="{{ resource.data }}"  controls *ngSwitchCase="'image/png'" />
         </div>
      </ng-container>
      <!--resource end-->

    </div>
    <!--resources end-->

    <!--procedures start-->
    <div class="row procedures" *ngIf="procedures">

      <div class="card col-xl">
        <div class="card-body">
          <div class="card-title area--grey">{{proceduresTitle}}</div>
          <ul class="card-text withLeftMargin">
            <ng-container *ngFor="let item of procedures">
              <li class="procedures" [ngClass]="{'withoutPoint': procedures.length == 1 }">
                {{ item }}
              </li>
            </ng-container>
          </ul>
        </div>
      </div>

    </div>
    <!--procedures end-->

    <div class="row bottom">

      <table class="table">
        <tr class="header">
          <th class="title area--green">{{'daily_activities.learning_support' | translate}}</th>
          <th class="title area--yellow">{{'daily_activities.se_developmen' | translate}}</th>
        </tr>

        <tr class="content">
          <td>
            <ul class="text">
              <ng-container *ngFor="let item of support">
                <li [ngClass]="{'withoutPoint': support.length == 1 }"> {{ item }} </li>
              </ng-container>
            </ul>
          </td>
          <td>
            <ul class="text">
              <ng-container *ngFor="let item of social">
                <li [ngClass]="{'withoutPoint': social.length == 1 }"> {{ item }} </li>
              </ng-container>
            </ul>
          </td>
        </tr>
      </table>

    </div>

    <div *ngIf="bunner.state" class="_bunner">
      <div class="_image" (clickOutside)="onClickedOutside($event)">
          <img *ngIf="bunner.type == 'img'" src="{{bunner.src}}" alt="">

          <video *ngIf="bunner.type == 'video'" id="movie" width="640" height="360" autoplay preload controls>
            <source src="{{bunner.src}}"/>
            <object id="flowplayer" type="application/x-shockwave-flash" width="640" height="360"></object>
          </video>
          
          <div *ngIf="bunner.type == 'img'" class="_button" (click)="bunner.state = false"> <span class="_close"></span>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container-lg loader" *ngIf="loader">
  <loader></loader>
</div>