import { Component, OnInit, OnDestroy } from '@angular/core';

import { XmlTemplatesService, ToastService } from '../services/index';
import { AppState, ResourcesService, IResource, ContentService, ContentResource, SessionService } from 'src/app/services';

import { IParameters } from './filters-panel/filters-panel.component';
import { HttpErrorResponse } from '@angular/common/http';

const xml = require('xml-js');

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit, OnDestroy {

  public loader: boolean = false;
  public school: any;
  /**
   * Value from textarea
   */
  public inputValue: string = null;

  /**
   * Flag to indicate that template was changed.
   */
  public templateChanged: boolean = false;

  /**
   * Filter parameters.
   */
  public parameters: IParameters = {
    language: null,
    mode: null,
    age: null,
    week: null,
    day: null,
    activity: null,
  };

  /**
   * List of `tabs`
   */
  public tabsList: string[] = ['Editor', 'Resources'];

  /**
   * Title of `Active Tab`
   */
  public activeTab: string = this.tabsList[0];

  /**
   * Flag to `open/hide` block with views.
   */
  public loadView = false;

  /**
   * Parsed xml data from input.
   */
  public parsedXml: any = null;

  /**
   * List of resources.
   */
  public resources: IResource[] = null;

  /**
   * Flag to indicate that parameters changed and resources in resource tab must be cleared.
   */
  public clearResources: boolean = false;

  /**
   * Version of template.
   */
  public version: string = null;

  /**
   * Label of progress bar.
   */
  public progressBarLabel: EProgressLabel = EProgressLabel.loading;

  /**
   * Flag to `show/hide` progress bar.
   */
  public progressBar: boolean = false;

  constructor(
    private appState: AppState,
    private xmlTemplates: XmlTemplatesService,
    private resourcesService: ResourcesService,
    private contentService: ContentService,
    private toastService: ToastService,
    private sessionService: SessionService
  ) {
     
    this.appState.store('role', 'coursecreator', true);
  }

  public ngOnInit(): void {
    this.school = this.appState.restore('school');
    this.vmLoadResources();
  }

  public ngOnDestroy(): void {
    // Clear resources in service.
    this.resourcesService.clear();
  }

  /**
   * Method to switch between tabs.
   * @param e Tab title
   */
  public vmTabsSwitch(e: string): void {
    this.activeTab = e;

    if (e === this.tabsList[0]) {
      this.vmLoadResources();
    }
  }

  public vmOnInputChange(e: Event): void {
    if (!!this.inputValue) {
      this.templateChanged = true;
      this.vmParseXml();
    }
  }

  /**
   * Method to indicate that template was changed by any action in resources tab.
   * @param e true/false
   */
  public vnOmResourcesChange(e: boolean): void {
    this.templateChanged = e;
  }

  /**
   * Method to load content.
   * @param parameters List of parameters from filters
   */
  public vmOnLoad(parameters: IParameters): void {
    // If parameters exists
    // Try to load existing template according to params
    // If no template found load default template.
    if (parameters) {
      let promise: Promise<any> = null;
      this.parameters = parameters;
      this.inputValue = null;
      this.loadView = true;
      this.progressBarLabel = EProgressLabel.loading;
      this.progressBar = true;
      this.resourcesService.clear();
      this.clearResources = true;

      switch (parameters.mode) {
        case 'da':
        case 'wda': {
          promise = this.contentService.getDailyActivities(parameters.language, parameters.age, parameters.week, parameters.day, parameters.activity, false);

          break;
        }
        case 'ds':
        case 'wds': {
          promise = this.contentService.getDailyStandards(parameters.language, parameters.age, parameters.week, parameters.day, parameters.activity, false);

          break;
        }
        case 'lp':
        case 'wlp': {
          promise = this.contentService.getLessonPlans(parameters.language, parameters.age, parameters.week, false);

          break;
        }
        case 'mtweek': {
          promise = this.contentService.getMaterials(parameters.language, parameters.age, parameters.week, null, false);

          break;
        }
        case 'hc':
        case 'whc': {
          promise = this.contentService.getHomeConnections(parameters.language, parameters.age, parameters.week, this.parameters.day, false);

          break;
        }

        default: {
          this.progressBar = false;
          this.toastService.showDanger('Cant load content. Unknown Mode.');

          break;
        }
      }

      promise
        .then((data: IContentResponse) => {
          // Load resources.
          this.vmLoadResources();

          this.inputValue = data.template;
          this.version = data.version;

          this.progressBarLabel = EProgressLabel.completed;
          this.vmParseXml();

          this.toastService.showSuccess('Content loaded.');

          this.progressBar = false;
          this.clearResources = false;

          // Switch to `Editor tab`
          this.vmTabsSwitch(this.tabsList[0]);

          return data;
        })
        .then((data: IContentResponse) => {
          if (data.files.length > 0) {
            this.contentService
              .getResources(data.files)
              .then((res) => {
                res.forEach((_res, index) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(_res);

                  reader.addEventListener('load', (event: ProgressEvent) => {
                    const target: any = event.target;

                    this.resourcesService.store({ title: data.files[index].filename, data: target.result, type: data.files[index].type });
                    this.vmLoadResources();
                  });
                });
              })
              .catch((error) => {
                console.log('Error To Get Resources: ', error);
              });
          }
        })
        .catch((error: HttpErrorResponse) => {
          if (error.status === 403) {
            this.inputValue = this.xmlTemplates.getTemplate(this.parameters.mode, this.parameters.age);
            this.version = '-';

            this.progressBarLabel = EProgressLabel.completed;
            this.vmParseXml();

            this.toastService.showSuccess('Content loaded.');

            this.progressBar = false;
            this.clearResources = true;

            // Switch to `Editor tab`
            this.vmTabsSwitch(this.tabsList[0]);
          } else {
            console.log('ERROR To Load Content: ', error);

            this.toastService.showDanger(`Couldn't load content.`);
          }
        });
    }
  }

  /**
   * Method to save created content.
   * @param parameters List of parameters
   */
  public async vmOnSave(parameters: IParameters): Promise<void> {
    
    this.loader = true;
    // Load resources before save.
    // It can be necessary when content saving from resource tab.
    this.vmLoadResources();

    // List of files ready to save.
    const files: ContentResource[] = [];

    let promise: Promise<any> = null;

    /**
     * Template file
     */
    const template: ContentResource = {
      filename: 'template.xml',
      body: new Blob([this.inputValue], { type: 'text/xml' }),
    };

    files.push(template);

    // Prepare resources for save.
    for (const resource of this.resources) {
      const blob = await fetch(resource.data).then((res) => res.blob());
      files.push({
        filename: resource.title,
        body: blob,
      });
    }

    this.progressBar = true;
    this.progressBarLabel = EProgressLabel.saving;

    switch (parameters.mode) {
      case 'da':
      case 'wda': {
        promise = this.contentService.saveDailyActivities(parameters.language, parameters.age, parameters.week, parameters.day, parameters.activity, files);
        break;
      }
      case 'ds':
      case 'wds': {
        promise = this.contentService.saveDailyStandards(parameters.language, parameters.age, parameters.week, parameters.day, parameters.activity, files);
        break;
      }
      case 'lp':
      case 'wlp': {
        promise = this.contentService.saveLessonPlans(parameters.language, parameters.age, parameters.week, files);
        break;
      }
      case 'mtweek': {
        promise = this.contentService.saveMaterials(parameters.language, parameters.age, parameters.week, null, files);
        break;
      }
      case 'hc':
      case 'whc': {
        promise = this.contentService.saveHomeConnections(parameters.language, parameters.age, parameters.week, parameters.day, files);
        break;
      }

      default: {
        console.log('Unknown Mode: ', parameters.mode);

        this.toastService.showDanger('Cant save content. Unknown Mode.');
        break;
      }
    }

    promise
      .then((resp: { version: number }) => {
        this.version = `${resp.version}`;
        this.templateChanged = false;

        this.toastService.showSuccess('Your changes saved!');

        this.progressBarLabel = EProgressLabel.completed;
        this.progressBar = false;
        this.loader = false;
      })
      .catch((error: HttpErrorResponse) => {
        console.log('ERROR. To Save Content Folder.', error);

        this.toastService.showDanger('Your changes was not saved.', true);

        this.progressBar = false;
        this.loader = false;
      });
  }

  /**
   * Method to clear template and hide view
   * when user agreed that his changes will ne lost.
   */
  public vmOnTemplateClear(e: any): void {
    this.inputValue = null;
    this.parameters.mode = null;
    this.templateChanged = false;
    this.clearResources = true;
    this.loadView = false;
    this.resourcesService.clear();
  }

  public vmOnModalClose(e: boolean): void {
    this.progressBarLabel = EProgressLabel.loading;
  }

  /**
   * Method to parse xml template from input.
   */
  private vmParseXml(): void {
    try {
      this.parsedXml = xml.xml2js(this.inputValue).elements[0];
      console.log('dd');
    } catch (error) {
      this.toastService.showDanger('Invalid XML Template.', true);
      this.templateChanged = false;
      this.parsedXml = null;
    }
  }

  /**
   * method to load resources from `resources service`.
   */
  private vmLoadResources(): void {
    this.resources = this.resourcesService.getResources;
  }
}

export interface IContentResponse {
  content: string;
  files: Array<{ filename: string; url: string; type: string }>;
  template: string;
  version: string;
}

enum EProgressLabel {
  loading = 'Loading...',
  saving = 'Saving...',
  completed = 'Completed!',
}
